import React from "react";
import { withRouter } from "react-router-dom";
import AlertComponent from "../components/alert-component";
import {makeStyles} from "@material-ui/styles";

const notFoundStyles = makeStyles({
  backgroundBlur: {
    background: "radial-gradient(circle, rgba(102,255,255,0.2) 0%, rgba(22,24,24,1) 40%)",
    backgroundSize: 'cover',
    width: '100%',
    height: '100vh',
    backgroundPositionY: '-20vh',
    backgroundPositionX: 'center',
    backgroundRepeat: 'no-repeat',
    position: 'absolute',
    zIndex: '0',
  },
  formWrap: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center'
  },
  waitlistForm: {
    width: '100%',
    paddingBottom: '48px',
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '500px',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: '1',
  },
  graphic: {
    backgroundImage: "url('/assets/nimble-graphic.png')",
    background: "no-repeat center center",
    backgroundSize: "contain",
    width: "100%",
    height: '200px',
    marginTop: '120px'
  },
  copyText: {
    fontWeight: '400',
    fontSize: '1em',
    textAlign: 'center',
    color: '#66FFFF',
    marginTop: '0'
  },
});

const NotFound = () => {
  const classes = notFoundStyles();
  return <div className={classes.formWrap}>
    <div className={classes.backgroundBlur}></div>
    <div className={classes.waitlistForm}>
      <div className={classes.graphic}></div>
      <h4 className={classes.copyText}>
        Uh oh!<br/>
          You have taken a wrong turn.
      </h4>
    </div>
  </div>
};

export default withRouter(NotFound);
