import React, { Fragment } from "react";
import NavBar from "../components/navbar-component";
import { makeStyles } from "@material-ui/styles";
import { useLocation } from 'react-router-dom';

const useLayoutStyles = makeStyles({
  content: {
    minHeight: "calc(100vh - 64px)",
    width: "100%",
    justifyContent: "center",
    overflowY: "auto",
    "&::-webkit-scrollbar": {
      display: "none", // Chrome, Safari, Opera
    },
  },
});


export const ExternalLayout = (props) => {
  const classes = useLayoutStyles();
  const location = useLocation();
  const path = location.pathname;
  const newPostPage = path === "/post/new";
  return (
    <div className={classes.content}>
      <Fragment>
        {!newPostPage && <NavBar />}
        <div>{props.children}</div>
      </Fragment>
    </div>
  );
};
