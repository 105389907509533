import { ethers } from "ethers";
import {
  signupAndJoinCommunity,
  connectWalletWithSignature,
  getWalletConnectionSignatureMessage,
  checkInviteCodeValidation,
  addUserIntoWaitlist,
  getUserSummaryData,
  getCommunitySummary,
  getInviteCodeDetail
} from "../services/auth-service";


export const useAuth = () => {
  const signupWithWallet = async (inviteCode, communityId, username, discordHandle, accessToken) => {
    try {

      return await signupAndJoinCommunity(inviteCode, communityId, username, discordHandle, accessToken);
    } catch (error) {
      console.error("Error logging in with wallet:", error);
      throw error;
    }
  };

  const validateInviteCode = async (inviteCode, token) => {
    const inviteDetail = await getInviteCodeDetail(inviteCode, token);
    const data = await checkInviteCodeValidation(inviteCode.trim(), token, inviteDetail.community);
    return data;
  }

  const connectWallet = async () => {
    const message = await getWalletConnectionSignatureMessage();
    if (window.ethereum) {
      const provider = new ethers.BrowserProvider(window.ethereum);
      const network = await provider.getNetwork();

      if (network.name !== process.env.REACT_APP_NETWORK_NAME) {
        try {
          await window.ethereum.request({
            method: 'wallet_switchEthereumChain',
            params: [{ chainId: process.env.REACT_APP_CHAIN_ID }],
          });
        } catch (switchError) {
          const network = await provider.getNetwork();
          if (network.chainId !== process.env.REACT_APP_CHAIN_ID) {
            return {
              error: "Please connect to " + process.env.REACT_APP_NETWORK_NAME,
              walletAddress: null,
              message: null,
              signature: null
            };
          }
        }
      }
      const signer = await provider.getSigner();
      const signature = await signer.signMessage(message);
      const walletAddress = await signer.getAddress();
      const data = await connectWalletWithSignature(
        message,
        signature,
        walletAddress
      );
      return { ...data, walletAddress: walletAddress };
    } else {
      return {
        error: "MetaMask is not installed",
        walletAddress: null,
        message: null,
        signature: null
      }
    }
  };

  const addIntoWaitlist = async (discordHandle, email, token) => {
    try {
      const waitlistRecord = await addUserIntoWaitlist(
        discordHandle,
        email,
        token
      );
    } catch (error) {
      console.error("Error logging in with wallet:", error);
      throw new Error(error);
    }
  };

  const getCommunityData = async (communityId, token) => {
    try {
      return await getCommunitySummary(communityId, token);
    } catch (error) {
      console.error("Error logging in with wallet:", error);
      throw new Error(error);
    }
  };

  const getUserSummary = async (token) => {
    try {
      return await getUserSummaryData (token);
    } catch (error) {
      console.error("Error logging in with wallet:", error);
      throw new Error(error);
    }
  };


  return { signupWithWallet, addIntoWaitlist, connectWallet, validateInviteCode, getCommunityData, getUserSummary };
};
