import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/styles";
import { Button, CircularProgress } from "@mui/material";
import { useStoreState } from 'easy-peasy';
import { ethers } from "ethers";
import { CommunityKeysV1__factory } from "nimble-community-contracts-sdk";
import { useCurrentSharePrice } from "../hooks/useCurrentSharePrice";
import axios from "axios";
import { TRANSACTION, ERROR_CODE } from "../constants";

const useStyles = makeStyles((theme) => ({
    contentContainer: {
        marginTop: '40px',
        paddingLeft: '37px',
        paddingRight: '37px',
    },
    closeButton: {
        position: 'absolute',
        top:'0px',
        right: '10px',
        backgroundColor: 'transparent',
        border: 'none',
        color: '#FFFFFF',
        cursor: 'pointer',
        fontSize: '30px',
    },
    communityName: {
        color: '#66FFFF',
        fontSize: '16px',
        fontWeight: 'bold',
    },
    copyText: {
        fontWeight: '700',
        fontSize: '20px',
        textAlign: 'center',
        color: 'white',
        marginBottom: '80px',
      },
    popupOverlay: {
        position: 'fixed',
        width: "100%",
        height: "100%",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backdropFilter: 'blur(8px)',
        zIndex: 1300,
    },
    popupContent: {
        position: 'relative',
        backgroundColor: '#161818',
        border: "1px solid #66FFFF",
        borderRadius: "20px",
        width: '100%',
        maxWidth: '345px',
        height: '370px',
        overflowY: 'auto',
    },

    transactionButton: {
        width: "100%",
        height: "48px",
        backgroundColor: "#66FFFF !important",
        color: "#161818 !important",
        borderRadius: "1000px !important",
        fontSize: "18px !important",
        textTransform: "none",
        fontWeight: "bold !important",
        boxShadow: "none",
        "&:hover": {
            backgroundColor: "#00D4D4",
            boxShadow: "none",
        },
        marginTop: '15px !important',
    },
    loadingIndicator: {
        fontSize: '16px !important',
        color: '#161818 !important',
        fontWeight: 'bold !important',
    },
}));

const JoinPopup = ({ onClose, onActionComplete, communityName, communityWalletAddress, id }) => {

    const classes = useStyles();
    const [isLoading, setIsLoading] = useState(false);

    const token = useStoreState((state) => state.auth.token);
    const userWalletAddress = useStoreState((state) => state.auth.walletAddress);
    const sharePrice = useCurrentSharePrice(communityWalletAddress);


    const handleJoinAsMember = async () => {
        const provider = new ethers.BrowserProvider(window.ethereum);
        const signer = await provider.getSigner();
        const contractAddress = process.env.REACT_APP_COMMUNITY_CONTRACT_ADDRESS;
        const communityContract = CommunityKeysV1__factory.connect(contractAddress, signer);

        let receipt;
        let tx;
        try {
          const options = {
            gasLimit: TRANSACTION.GAS_LIMIT,
            value: ethers.parseEther(`${sharePrice}`, "ether"),
          };
          tx = await communityContract.buyKeys(communityWalletAddress, 1, options);

          setIsLoading(true);
          receipt = await tx.wait().then(async (receivedReceipt) => {
            setIsLoading(false);
            await handleJoinAsGuest();
            return receivedReceipt;
          });
        } catch (error) {
          console.error("Error buying membership:", error);
          setIsLoading(false);
          onActionComplete();
          onClose();
        }
    };

    const handleJoinAsGuest = async() => {
        try {
            const { data } = await axios.request({
                method: 'POST',
                url: `${process.env.REACT_APP_HOST_ORIGIN}/v1/community/commonuser/${id}/community`,
                headers: {
                  'Authorization': `Bearer ${token}`
                },
            });
            onClose();
            onActionComplete();

        } catch (error) {
            console.log("Join failed: ", error);
            onClose();
            onActionComplete();
        }
    };

    return (
        <div className={classes.popupOverlay}>
            <div className={classes.popupContent} onClick={(e) => e.stopPropagation()}>
                <div>
                    <button className={classes.closeButton} onClick={onClose} disabled={isLoading} >&times;</button>

                    <div className={classes.contentContainer}>
                        <h4 className={classes.copyText}>Join Nimbus open beta!<br/>Your community awaits</h4>

                        <Button type="button" className={classes.transactionButton} onClick={handleJoinAsMember} disabled={isLoading}>
                            {isLoading ? (
                                <div>
                                    <CircularProgress size={24} style={{ color: '#161818', marginRight: '10px' }} />
                                    <span className={classes.loadingIndicator}>Transaction is Processing...</span>
                                </div>
                            ) : (
                                'Buy Membership'
                            )}
                        </Button>

                        <Button type="button" className={classes.transactionButton} onClick={handleJoinAsGuest} disabled={isLoading}>
                            Join as a Guest
                        </Button>
                    </div>
                </div>
            </div>
        </div>
  );
};

export default JoinPopup;
