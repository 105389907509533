import { makeStyles } from "@material-ui/core";
import React, { useState, useRef } from "react";
import { withRouter } from "react-router-dom";
import CommonButton from "../components/CommonButton.js"
import { useCallback } from "react";
import axios from "axios";
import history from "../util/history-util.js";
import { useStoreState } from "easy-peasy";
import InsertPhotoIcon from '@mui/icons-material/InsertPhoto';

const usePostsPageStyles = makeStyles({
  page: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    width: "100%",
    height: "100vh",
    zIndex: 20,
    backgroundColor: "#161818"
  },
  header: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    height: "50px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "0 20px",
  },
  backButton: {
    position: "absolute",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
  },
  pageTitle: {
    color: "#fff",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "normal",
    letterSpacing: "0.48px",
    margin: "0 auto",
  },
  body: {
    width: "100%",
    height: "100%",
    padding: "50px 20px 0 20px",
  },
  title: {
    width: "100%",
    height: "50px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "0 14px 15px 14px",
    borderBottom: "1px solid #383838",
    "& input": {
      width: "100%",
      height: "100%",
      border: "none",
      outline: "none",
      fontSize: "18px",
      backgroundColor: "transparent",
      color: "#BBB",
      fontStyle: "normal",
      fontWeight: 600,
      lineHeight: "normal",
      letterSpacing: "-0.09px"
    },
  },
  description: {
    width: "100%",
    height: "367px",
    display: "flex",
    flexDirection: "column",
    padding: "15px 14px",
    borderBottom: "1px solid #383838",
    "& textarea": {
      width: "100%",
      height: "auto",
      flexGrow: 1,
      border: "none",
      outline: "none",
      fontSize: "14px",
      backgroundColor: "transparent",
      color: "#BBB",
      fontStyle: "normal",
      fontWeight: 300,
      lineHeight: "normal",
      letterSpacing: "0.28px",
    },
  },
  controllers: {
    width: "100%",
    height: "50px",
    display: "flex",
    alignItems: "center",
    justifyContent: "right",
    padding: "0 0",
    gap: "10px",
  },
  uploadImage: {
    display: "flex", 
    justifyContent: "flex-start", 
    marginTop: "10px",
    color: 'white'
  }

});

const PostNewPage = () => {

  const classes = usePostsPageStyles();
  const [title, setTitle] = React.useState("");
  const [description, setDescription] = React.useState("");
  const [imagePreview, setImagePreview] = useState(null);
  const [isPosting, setIsPosting] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const fileInput = useRef(null);
  const { token } = useStoreState((state) => state.auth);
  const selectedCommunity = useStoreState((state) => state.settings.currentSelectedCommunity);

  const back = useCallback(() => {
    history.goBack();
  }, []);

  const uploadImage = async (file, postId) => {
    setIsLoading(true);
    const formData = new FormData();
    formData.append('file1', file);
  
    try {
      const response = await axios.request({
        method: 'PUT',
        url: `${process.env.REACT_APP_HOST_ORIGIN}/v1/post/${postId}/image`,
        headers: {
          'Authorization': `Bearer ${token}`,
        },
        data: formData
      });
    } catch (error) {
      console.error('Error uploading file:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleFileSelect = async (event) => {
    const file = event.target.files[0];
    if (file) {
      const previewUrl = URL.createObjectURL(file);
      setImagePreview(previewUrl);
    }
  };

  const onPost = useCallback(async () => {
    if (isPosting) {
      return;
    }
    if (!title) return;
    setIsPosting(true);
    try {
      const res = await axios.request({
        method: 'POST',
        url: `${process.env.REACT_APP_HOST_ORIGIN}/v1/post`,
        data: {
          title,
          content: description ?? '',
          communityId: selectedCommunity.id,
          mainImage: ''
        },
        headers: {
          'Authorization': `Bearer ${token}`
        },
      });
      const postId = res.data.id;
      if (fileInput.current.files[0]) {
        await uploadImage(fileInput.current.files[0], postId);
      }
      back();
      setIsPosting(false);
    } catch (err) {
      setIsPosting(false);
      console.log('err', err);
    }
  }, [back, description, title, token, isPosting]);

  return (<div className={classes.page}>
    <div className={classes.header}>
      <div className={classes.backButton} onClick={back}><img src="/assets/bx_arrow-back.svg" alt="back" /></div>
      <div className={classes.pageTitle}>NEW POST</div>
    </div>
    <div className={classes.body}>
      <div className={classes.title}>
        <input type="text" placeholder="Title" value={title} onChange={(event) => setTitle(event.target.value)} />
      </div>
      <div className={classes.description}>
        <textarea placeholder="Description (optional)" value={description} onChange={(event) => setDescription(event.target.value)} />
        <div className={classes.uploadImage} >
          <input
            type="file"
            accept="image/*"
            onChange={handleFileSelect}
            style={{ display: 'none' }}
            ref={fileInput}
          />
          <InsertPhotoIcon onClick={() => fileInput.current.click()} />
          {imagePreview && <img src={imagePreview} alt="Preview" style={{ marginLeft: '10px', maxHeight: '100px' }} />}
        </div>
      </div>
      <div className={classes.controllers}>
        <CommonButton title="Cancel" styles={{width: "100px"}} onClick={back} />
        <CommonButton title="Post" type="primary" styles={{width: "100px"}} disabled={!title} onClick={onPost} />
      </div>
    </div>
  </div>);
};

export default withRouter(PostNewPage);
