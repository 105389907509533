import React from "react";
import { withRouter } from "react-router-dom";
import { makeStyles } from "@material-ui/styles";
import { withTheme } from "@material-ui/core/styles";

import IconButton from "@material-ui/core/IconButton";
import Toolbar from "@material-ui/core/Toolbar";
import AppBar from "@material-ui/core/AppBar";

import { compose } from "../util/functional-util";
import history from "../util/history-util";
import { CommunitySwitcherComponent } from "./community-switcher-component";
import { CreatePostComponent } from "./create-post-component";
import get from "lodash/get";
import {useStoreActions, useStore, useStoreState} from "easy-peasy";
import { Image } from "@material-ui/icons";
import {Button, Slide, useScrollTrigger} from "@mui/material";
import redirect from "react-router-dom/Redirect";
import ConnectWalletButton from "./connect-wallet-component";
import { useLocation } from 'react-router-dom';
import ActionHeaderButtons from "./action-header-components";
import { useParams } from 'react-router-dom';

const useNavStyles = makeStyles({
  appBar: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    backgroundColor: "#161818",
    paddingBottom: '10px',
    zIndex: 2,
    paddingTop: "16px",
  },
  mainToolbar: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    minHeight: 0,
  },
  logo: {
    width: "110px",
  },
  logoWrapper: {
    display: "flex",
    flexDirection: "column",
    justifyItems: "flex-start",
  },
  actionWrapper: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "center",
  },
  navIcon: {
    color: "#3c3599",
  },
  settingsButton: {
    marginRight: -18,
  },
  title: {
    fontSize: "1.125rem",
    color: "#3c3599",
    fontWeight: 800,
    flexGrow: 1,
  },
  connectWalletButton: {
    " &.MuiButtonBase-root": {
      fontSize: "1rem",
      backgroundColor: "rgba(255, 255, 255, 0.1)",
      marginLeft: "auto",
      minWidth: "119px",
      height: "29px",
      borderRadius: "1000px",
      border: "1.5px solid",
    },
  },
});

const NavBar = () => {
  const classes = useNavStyles();
  //todo use the last selected community key to lookup the community's name
  const { currentSelectedCommunity } = useStoreState((state) => state.settings);
  const { isAuthenticated } = useStoreState((state) => state.auth);
  const pathname = window.location.pathname;
  const showCommunitySwitcher = /^\/(c|stats)\/.+/.test(pathname);
  const isCommunityPage = /^\/c\/.+/.test(pathname);
  
  // todo use this code to set the authenticated user and specify their community
  const { updateAuth } = useStoreActions((dispatch) => ({
    updateAuth: dispatch.auth.updateAuth,
  }));

  const token = useStoreState((state) => state.auth.token);

  return (
      <div className={classes.root}>
          <AppBar className={classes.appBar} elevation={0}>
            <Toolbar className={classes.mainToolbar}>
              <div className={classes.logoWrapper}>
                <img
                    src={"/assets/logo.svg"}
                    className={classes.logo}
                    alt={"Nimble"}
                />
              </div>
              { isAuthenticated && isCommunityPage &&
                  <div className={classes.actionWrapper}>
                    <ActionHeaderButtons />
                  </div>
              }
              {window.location.pathname !== '/login' && !isAuthenticated &&
                  <div className={classes.actionWrapper}>
                    <ConnectWalletButton className={classes.connectWalletButton}/>
                  </div>
              }
            </Toolbar>

            {showCommunitySwitcher && (
              <Toolbar style={{minHeight: 0}}>
                <div style={{display: 'flex'}}>
                  <CommunitySwitcherComponent></CommunitySwitcherComponent>
                </div>
              </Toolbar>
            )}
          </AppBar>
      </div>
  );
};

export default compose(withRouter)(NavBar);
