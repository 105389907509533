import { makeStyles } from "@material-ui/core";
import React, { useState, useEffect, useRef } from "react";
import { withRouter } from "react-router-dom";
import {useStoreActions, useStoreState} from "easy-peasy";
import {handleAuthError} from "../services/auth-service";
import { useCallback } from "react";
import { Paper, Button, Avatar } from "@mui/material";
import axios from "axios";
import PersonIcon from '@mui/icons-material/Person';
import history from "../util/history-util.js";

const avatarEditionStyles = makeStyles({
  page: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    width: "100%",
    height: "100vh",
    zIndex: 20,
    backgroundColor: "#161818"
  },
  header: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    height: "50px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "0 20px",
  },
  closeButton: {
    position: "absolute",
    right: 20,
    top: 8,
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    backgroundColor: 'transparent',
    border: 'none',
    color: '#FFFFFF',
    fontWeight: 600,
    fontSize: '20px',
  },
  pageTitle: {
    color: "#fff",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "normal",
    letterSpacing: "0.48px",
    margin: "0 auto",
  },
  body: {
    width: "100%",
    height: "100%",
    padding: "50px 20px 0 20px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatarEditionWrap: {
    maxWidth: "345px",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    " &.MuiPaper-root": {
      backgroundColor: "transparent",
      color: "#ffffff"
    },
  },
  editButton: {
    " &.MuiButtonBase-root": {
      marginTop: "12px",
      width: "30%",
      height: "48px",
      backgroundColor: "#FFFFFF",
      color: "#161818",
      borderRadius: "1000px",
      padding: "10px ",
      fontSize: "14px",
      textTransform: "none",
      fontWeight: "bold",
      boxShadow: "none",
      "&:hover": {
        backgroundColor: "#00D4D4",
        boxShadow: "none",
      },
    }
  },
});

const AvatarEdition = (props) => {

  const classes = avatarEditionStyles();
  const [avatar, setAvatar] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const fileInput = useRef(null);
  const token = useStoreState((state) => state.auth.token);

  const back = useCallback(() => {
    history.goBack();
  }, []);

  const clearAuth = useStoreActions(
    (dispatch) => dispatch.auth.clearAuth
  );

  const uploadImage = async (file) => {
    setIsLoading(true);
    const formData = new FormData();
    formData.append('file1', file);
  
    try {
      const response = await axios.request({
        method: 'PUT',
        url: `${process.env.REACT_APP_HOST_ORIGIN}/v1/community/user/profile/avatar`,
        headers: {
          'Authorization': `Bearer ${token}`,
        },
        data: formData
      });
    } catch (error) {
      console.error('Error uploading file:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleFileSelect = async (event) => {
    const file = event.target.files[0];
    if (file) {
      await uploadImage(file);
    }
  };

  useEffect(() => {
    const fetchUserInfo = async () => {
      try {
        const { data } = await axios.request({
          method: 'GET',
          url: `${process.env.REACT_APP_HOST_ORIGIN}/v1/community/user/info`,
          headers: {
            'Authorization': `Bearer ${token}`
          },
        });
        if (data) {
            setAvatar(data.avatarUrl);
        }
      } catch (error) {
        console.error('Error fetching user info:', error);
        handleAuthError(error, clearAuth);
      }
    };
    if (token) {
      fetchUserInfo();
    }
  }, [token, clearAuth, isLoading]);

  return (
  <div className={classes.page}>
    <div className={classes.header}>
      <div className={classes.pageTitle}>Profile Image</div>
      <div className={classes.closeButton} onClick={back}>X</div>
    </div>
    <div className={classes.body}>
        <Paper elevation={0} className={classes.avatarEditionWrap}>
            <Avatar
                sx={{
                    width: '80%',
                    height: '100%',
                    aspectRatio: "1 / 1",
                    bgcolor: avatar ? 'transparent' : '#88FFFF',
                    borderRadius: '0%',
                    fontSize: '30px'
                }}
                src={avatar || undefined}
                aria-label="user"
            >
                {!avatar && 
                    <PersonIcon 
                        sx={{ 
                            width: '80%',
                            height: '100%',
                            aspectRatio: "1 / 1",
                            fontSize: 'inherit' }} />}
            </Avatar>
            
        </Paper>
        <input
          type="file"
          accept="image/*"
          onChange={handleFileSelect}
          style={{ display: 'none' }}
          ref={fileInput}
        />
        <Button className={classes.editButton} onClick={() => fileInput.current.click()}>Edit Image</Button>
    </div>
  </div>);
};

export default withRouter(AvatarEdition);

