import {Button, IconButton, TextField} from "@mui/material";
import { makeStyles } from "@material-ui/styles";
import React, {useState} from "react";
import { withRouter } from "react-router-dom";
import { compose } from "../util/functional-util";
import {createInviteCode} from "../services/community-service";
import {useStoreState} from "easy-peasy";
import CloseIcon from "@mui/icons-material/Close";

const alertStyles = makeStyles({
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.7)",
    backdropFilter: "blur(8px)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 1000,
  },
  alertBox: {
    width: "100%",
    maxWidth: "300px",
    minHeight: "180px",
    backgroundColor: "#161818",
    border: "0.5px solid #66FFFF",
    padding: "20px",
    borderRadius: "20px",
    boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 1001,
  },
  alertBoxInput: {
    " &.MuiFormControl-root": {
      width: '100%',
      marginBottom: '20px'
    }
  },
  alertBoxWrap: {
    padding: "0 12px",
    width: "100%",
    display: "flex",
    justifyContent: "center"
  },
  message: {
    fontSize: "18px",
    color: "white",
    width: "100%",
    textAlign: "center",
    marginBottom: "20px",
  },

  titleWrap: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    alignItems: "center",
    marginBottom: '12px'
  },
  title: {
    fontSize: '1.2em',
    fontWeight: '600'
  },
  closeButton: {
    width: "100%",
    height: "48px",
    backgroundColor: "#66FFFF !important",
    color: "#161818 !important",
    borderRadius: "1000px !important",
    padding: "10px 60px",
    fontSize: "18px !important",
    textTransform: "none",
    fontWeight: "bold !important",
    boxShadow: "none",
    "&:hover": {
      backgroundColor: "#00D4D4",
      boxShadow: "none",
    },
  },
});

const CreateInviteComponent = ({ isOpen, onClose }) => {
  const classes = alertStyles();
  const token = useStoreState((state) => state.auth.token);
  const selectedCommunity = useStoreState((state) => state.settings.currentSelectedCommunity);
  const [inviteLink, setInviteLink] = useState(null);
  const [errorState, setErrorState] = useState(null);
  const [codeCopied, setCodeCopied] = useState(null);

  if (!isOpen) return null;

  //todo when we have multiple communities, use the correct community ID
  const generateCode = () => {
    createInviteCode(selectedCommunity?.id, token).then((result) => {
      if (result && result.statusCode >= 400) {
        setErrorState('Invite Limit Reached');
      }
      if (result && result.statusCode > 400) {
        setErrorState('Try again later');
      }
      if (typeof result === 'string') {
        let protocol = 'https://';
        if (window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1') {
          protocol = 'http://';
        }
        const inviteUrl = protocol + window.location.host + '/join?code=' + result;
        setInviteLink(inviteUrl);
      }
    });
  }

  const copyToClipboard = () => {
    navigator.clipboard.writeText(inviteLink);
    setCodeCopied(true);
  }

  const ignoreClick = (e) => {
    e.stopPropagation();
  }


  return (
      <div className={classes.overlay} onClick={onClose}>
        <div className={classes.alertBoxWrap}>
          <div className={classes.alertBox}>
            <div className={classes.titleWrap} onClick={e => ignoreClick(e)}>
              <div className={classes.title}>Invite Friends</div>
              <IconButton onClick={onClose}>
                <CloseIcon />
              </IconButton>
            </div>
            <TextField
                disabled
                className={classes.alertBoxInput}
                label={'Invite Link'}
                value={inviteLink || errorState || ''}
            />
            {!inviteLink &&
                <Button type="button" className={classes.closeButton} onClick={
                  (event) => {
                    ignoreClick(event);
                    generateCode();
                  }
                }>
                  Generate Link
                </Button>
            }
            { inviteLink &&
                <Button type="button" className={classes.closeButton} onClick={
                  (event) => {
                    ignoreClick(event);
                    copyToClipboard();
                  }
                }>
                  {!codeCopied ? 'Copy Link' : 'Link Copied!'}
                </Button>
            }
          </div>
        </div>
      </div>
  );
};

export default compose(withRouter)(CreateInviteComponent);
