import { useCallback, useEffect, useState } from "react";
import BigNumber from "bignumber.js";
import { getContractViewer } from "../util/contractHelper";


const useCurrentSharePrice = (keysSubject = process.env.REACT_APP_CONTRACT_NIMBLE_KEYSSUBJECT, amount = 1) => {
  const [currentSharePrice, setCurrentSharePrice] = useState(0);

  const getPrice = useCallback(() => {
    getContractViewer().getBuyPriceAfterFee(keysSubject, amount).then((price) => {
      const price2 = (new BigNumber(price)).dividedBy(10 ** 18).toNumber();
      setCurrentSharePrice(price2);
   });
  }, [keysSubject, amount]);

  useEffect(() => {
    getPrice();

    const filter = getContractViewer().filters['Trade(address,address,bool,uint256,uint256,uint256,uint256,uint256)'];
    getContractViewer().on(filter,  (...args) => {
      getPrice();
    });
  }, [getPrice]);

  return currentSharePrice;
};

const useCurrentSellSharePrice = (keysSubject = process.env.REACT_APP_CONTRACT_NIMBLE_KEYSSUBJECT, amount = 1) => {
  const [currentSellSharePrice, setCurrentSellSharePrice] = useState(0);

  const getPrice = useCallback(() => {
    getContractViewer().getSellPriceAfterFee(keysSubject, amount).then((price) => {
      const price2 = (new BigNumber(price)).dividedBy(10 ** 18).toNumber();
      setCurrentSellSharePrice(price2);
   });
  }, [keysSubject, amount]);

  useEffect(() => {
    getPrice();

    const filter = getContractViewer().filters['Trade(address,address,bool,uint256,uint256,uint256,uint256,uint256)'];
    getContractViewer().on(filter,  (...args) => {
      getPrice();
    });
  }, [getPrice]);

  return currentSellSharePrice;
};

export { useCurrentSharePrice, useCurrentSellSharePrice };
