import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/styles";
import WalletIcon from '@mui/icons-material/Wallet';
import { Button, TextField, CircularProgress } from "@mui/material";
import { useStoreState } from 'easy-peasy';
import { useCurrentSharePrice, useCurrentSellSharePrice } from "../hooks/useCurrentSharePrice";
import { CommunityKeysV1__factory } from "nimble-community-contracts-sdk";
import { getContractViewer } from "../util/contractHelper";
import { ethers } from "ethers";
import { TRANSACTION, NUMBER_CONSTANTS, ERROR_CODE, COMMUNITY_NAME, CURRENCY } from "../constants"; 

const useStyles = makeStyles((theme) => ({
    contentContainer: {
        marginTop: '40px',
        paddingLeft: '37px',
        paddingRight: '37px',
    },
    closeButton: {
        position: 'absolute',
        top:'0px',
        right: '10px',
        backgroundColor: 'transparent',
        border: 'none',
        color: '#FFFFFF',
        cursor: 'pointer',
        fontSize: '30px',
    },
    communityName: {
        color: '#66FFFF',
        fontSize: '16px',
        fontWeight: 'bold',
    },
    flexRow: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        paddingRight: '10px',
    },
    line: {
        flex: 1,
        height: '1px',
        backgroundColor: '#FFFFFF',
        margin: '0 10px',
    },
    feeCount: {
        color: '#FFFFFF',
        fontSize: '14px',
        fontWeight: 'bold',
    },
    totalAssets: {
        height: '37px',
        backgroundColor: 'rgba(255, 255, 255, 0.05)',
        color: '#FFFFFF',
        borderRadius: '8px',
        marginTop: '10px',
        display: 'flex',
        alignItems: 'center',
    },
    icon: {
        marginLeft: '10px',
        marginRight: '10px',
    },
    inputField: {
        width: "100%",
        height: "48px",
        marginTop:'40px !important',
        "& .MuiInputBase-input": {
          borderRadius: "12px",
          backgroundColor: "rgba(0, 0, 0, 0.7)",
          color: "#bbbbbb",
        },
        "& .MuiInputLabel-root": {
          color: "#bbbbbb",
        },
        "& .MuiOutlinedInput-root": {
          "& fieldset": {
            border: "0px",
          },
          "&:hover fieldset": {
            border: "1px solid #66FFFF",
          },
          "&.Mui-focused fieldset": {
            border: "1px solid #66FFFF",
          },
        },
    },
    paddedTopDiv: {
        paddingTop: '20px',
    },
    popupOverlay: {
        position: 'fixed',
        width: "100%",
        height: "100%",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backdropFilter: 'blur(8px)',
        zIndex: 1300,
    },
    totalText: {
        fontSize: '14px',
        fontWeight: 600,
    },
    amountText: {
        fontSize: '16px',
        fontWeight: 700,
    },
    commonText: {
        fontSize: '10px',
        fontWeight: 600,
    },
    popupContent: {
        position: 'relative',
        backgroundColor: '#161818',
        border: "1px solid #66FFFF",
        borderRadius: "20px",
        width: '100%',
        maxWidth: '345px',
        height: '370px',
        overflowY: 'auto',
    },

    transactionSuccess: {
        marginLeft: '10px',
        color: 'green',
        fontSize: '16px',
        fontWeight: '700'
    },
    transactionFailure: {
        marginLeft: '10px',
        color: 'red',
        fontSize: '16px',
        fontWeight: '700'
    },
    transactionButton: {
        width: "100%",
        height: "48px",
        backgroundColor: "#66FFFF !important",
        color: "#161818 !important",
        borderRadius: "1000px !important",
        fontSize: "18px !important",
        textTransform: "none",
        fontWeight: "bold !important",
        boxShadow: "none",
        "&:hover": {
            backgroundColor: "#00D4D4",
            boxShadow: "none",
        },
        marginTop: '40px !important',
    },
    loadingIndicator: {
        fontSize: '16px !important',
        color: '#161818 !important',
        fontWeight: 'bold !important',
    },
}));

const MembershipTransactionPopup = ({ action, onClose, onActionComplete, communityName, communityWalletAddress, role }) => {
    const classes = useStyles();
    const [transactionAmount, setTransactionAmount] = useState(0);
    const [totalMembership, setTotalMembership] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [transactionStatus, setTransactionStatus] = useState(null);
    const [showStatus, setShowStatus] = useState(false);

    const token = useStoreState((state) => state.auth.token);
    const userWalletAddress = useStoreState((state) => state.auth.walletAddress);

    const currentUnitPrice = useCurrentSharePrice(communityWalletAddress, 1);
    const calculatedBuyPrice = useCurrentSharePrice(communityWalletAddress, transactionAmount);
    const calculatedSellPrice = useCurrentSellSharePrice(communityWalletAddress, Math.min(totalMembership, transactionAmount));
    const totalAssets = useCurrentSellSharePrice(communityWalletAddress, totalMembership);
    const isMATIC = communityName === COMMUNITY_NAME.CORNERMARKET || communityName === COMMUNITY_NAME.NIMBLE;

    const handleInputChange = (e) => {
        const transactionInputValue = e.target.value;
        if (/^\d*$/.test(transactionInputValue)) {
            const parsedTransactionAmount = parseInt(transactionInputValue, NUMBER_CONSTANTS.DECIMAL_BASE);
            let allowedTransactionAmount = 0;
    
            if (isNaN(parsedTransactionAmount)) {
                allowedTransactionAmount = 0;
            } else if (action === TRANSACTION.SELL) {
                allowedTransactionAmount = Math.min(parsedTransactionAmount, totalMembership, TRANSACTION.MAX_TRANSACTION_AMOUNT);
            } else {
                allowedTransactionAmount = Math.min(parsedTransactionAmount, TRANSACTION.MAX_TRANSACTION_AMOUNT);
            }
    
            setTransactionAmount(allowedTransactionAmount);
        }
    };

    const transactionHandle = async () => {
        if (transactionAmount === 0) {
            return;
        }
        setIsLoading(true); 

        try {
            const provider = new ethers.BrowserProvider(window.ethereum);
            const signer = await provider.getSigner();
            const contractAddress = process.env.REACT_APP_COMMUNITY_CONTRACT_ADDRESS;
            const communityContract = CommunityKeysV1__factory.connect(contractAddress, signer);
            const keysSubject = communityWalletAddress;
            
            if (action === TRANSACTION.BUY) {
                let receipt, tx;
                try {
                    const options = {
                        gasLimit: TRANSACTION.GAS_LIMIT,
                        value: ethers.parseEther(`${calculatedBuyPrice}`, "ether"),
                    };

                    tx = await communityContract.buyKeys(keysSubject, transactionAmount, options);
                    receipt = await tx.wait().then((receivedReceipt) => {
                        return receivedReceipt
                    });
                    setTotalMembership(transactionAmount + totalMembership); 
                } catch (error) {
                    console.error("Error buying membership:", error);
                    throw new Error("Error buying membership");
                }
                if (receipt.status !== ERROR_CODE.NO_ERROR ) {
                    throw new Error('Transaction of buying membership failed');
                }
            } else {
                let receipt, tx;
                try {
                    const options = {
                        gasLimit: TRANSACTION.GAS_LIMIT,
                    };
                
                    tx = await communityContract.sellKeys(keysSubject, transactionAmount, options);
                    receipt = await tx.wait().then((receivedReceipt) => {
                        return receivedReceipt
                    });
                    setTotalMembership(totalMembership - transactionAmount); 
                } catch (error) {
                    console.error("Error selling membership:", error);
                    throw new Error("Error selling membership");
                }
                if (receipt.status !== ERROR_CODE.NO_ERROR) {
                    throw new Error('Transaction of selling membership failed');
                }
            }
            setTransactionAmount(0);
            onActionComplete();
            setTransactionStatus('success');
            console.log("Transaction successful");
        } catch (error) {
            setTransactionStatus('failure');
            console.error("Error in transaction:", error);
        } finally {
            setShowStatus(true);
            setTimeout(() => setShowStatus(false), 3000);
            setIsLoading(false);
        }
        
    };

    const getButtonLabel = () => {
        if (action === TRANSACTION.SELL && transactionAmount == totalMembership) {
            return 'Sell All Membership';
        }
        return action;
    };

    const formatToken = (token) => {
        if (token && token.length > 10) {
            return `${token.substring(0, 5)}...${token.substring(token.length - 5)}`;
        }
        return token;
    };

    useEffect(() => {
        getContractViewer().keysBalance(communityWalletAddress, userWalletAddress).then((balance) => {
            const balanceStr = balance.toString();
            const numericBalance = parseInt(balanceStr.match(/\d+/)[0], NUMBER_CONSTANTS.DECIMAL_BASE);
            setTotalMembership(numericBalance);
         });
    }, []);

    return (
        <div className={classes.popupOverlay}>
        <div className={classes.popupContent} onClick={(e) => e.stopPropagation()}>
            {action && (
                <div>
                    <button className={classes.closeButton} onClick={onClose} disabled={isLoading} >&times;</button>

                    <div className={classes.contentContainer}>
                        <div className={classes.communityName}>
                            {communityName}
                            {showStatus && (
                                <span className={transactionStatus === 'success' ? classes.transactionSuccess : classes.transactionFailure}>
                                    {transactionStatus === 'success' ? 'Transaction Successful!' : 'Transaction Failed!'}
                                </span>
                            )}
                        </div>
                        <div className={`${classes.flexRow} ${classes.paddedTopDiv}`}>
                            <div className={classes.totalText}>Total (Including fees)</div>
                            <div className={classes.line}></div>

                            <div className={classes.amountText}>
                                { action === TRANSACTION.BUY ? calculatedBuyPrice.toFixed(5) : calculatedSellPrice.toFixed(5)} {isMATIC ? CURRENCY.MATIC : CURRENCY.ETH}
                            </div>
                        </div>

                        <div className={classes.totalAssets}>
                            <WalletIcon className={classes.icon} />

                        
                            <div className={classes.flexRow}>
                                <span className={classes.totalText}>{formatToken(token)}</span>
                                <div>
                                    <span className={classes.totalText}>{totalAssets.toFixed(5)}</span>
                                    <span className={classes.commonText}> {isMATIC ? CURRENCY.MATIC : CURRENCY.ETH} Available</span>
                                </div>
                            </div>
                        </div>

                        <TextField
                            className={classes.inputField}
                            label="Enter Transaction Amount"
                            variant="outlined"
                            value={transactionAmount}
                            onChange={handleInputChange}
                            inputProps={{ max: totalMembership, }} disabled={isLoading}/>

                        <Button type="button" className={classes.transactionButton} onClick={transactionHandle} disabled={isLoading}>
                            {isLoading ? (
                                <div>
                                    <CircularProgress size={24} style={{ color: '#161818', marginRight: '10px' }} />
                                    <span className={classes.loadingIndicator}>Transaction is Processing...</span>
                                </div>
                            ) : (
                                getButtonLabel()
                            )}
                        </Button>

                </div>

                
            </div>)}
        </div>
        </div>
  );
};

export default MembershipTransactionPopup;
