import React from 'react';
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles({
    userCard: {
      border: '0.5px solid #66FFFF',
      width: '100%',
      height: '68px', 
      boxSizing: 'border-box', 
      borderRadius: '10px',
      marginTop: '20px',
      color: 'white',
      display: 'flex', 
      flexDirection: 'column',
      justifyContent: 'center',
    },
    userBody: {
      display: 'flex',
      justifyContent: 'space-between',
      textAlign: 'center',
      paddingLeft:'20px',
      paddingRight:'20px'
    },
    infoBlock: {
      padding: '10px',
      borderRadius: '10px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },
    infoTitle: {
      fontSize: "12px",
    },
    infoContent: {
      fontSize: "16px",
      display: 'block',
      marginTop: '5px',
      color: 'white',
    },
  });

const UserSummaryCard = ({ communityCount, postCount, points }) => {
    const classes = useStyles();
    const communityCountDisplay = communityCount || 0;
    const postCountDisplay = postCount || 0;
    const pointsDisplay = points || 0;
    return (
      <div className={classes.userCard}>
        <div className={classes.userBody}>
          <div className={classes.infoBlock}>
            <span className={classes.infoTitle}>Communities</span>
            <strong className={classes.infoContent}>{communityCountDisplay}</strong>
          </div>
          <div className={classes.infoBlock}>
            <span className={classes.infoTitle}>Posts</span>
            <strong className={classes.infoContent}>{postCountDisplay}</strong>
          </div>
          <div className={classes.infoBlock}>
            <span className={classes.infoTitle}>Points</span>
            <strong className={classes.infoContent}>{pointsDisplay}</strong>
          </div>
        </div>
      </div>
    );
  };
  
  export default UserSummaryCard;
