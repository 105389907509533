import { changeEvaluationById } from "../services/community-service"; 

export const useCommunity = () => {
    const evaluationChange = async (id, likeOrUnlike, changeNum, token) => {
        try {
            await changeEvaluationById(id, likeOrUnlike, changeNum, token);
        } catch (error) {
          throw error;
        }
      }

  return { evaluationChange };
};
