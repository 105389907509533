import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/styles";
import { useCommunity } from "../hooks/community-hook";
import { useHistory } from 'react-router-dom';
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import {
  Avatar,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Typography,
} from "@mui/material";
import { ArrowUpward, ArrowDownward, CommentRounded } from "@material-ui/icons";
import { useStoreState } from "easy-peasy";
import IconButton from "@material-ui/core/IconButton";
import moment from "moment";
import CommentsDialog from "./comments-dialog";
import { ellipsisInMiddle } from "../util/misc";
import { ROLE } from "../constants";

dayjs.extend(relativeTime)

const usePostStyles = makeStyles({

  likeButton: {
    padding: "4px 12px",
  },
  likeIcon: {
    color: "#ffffff",
    '&.active': {
      color: "#66FFFF",
    },
  },
  likeText: {
    paddingLeft: "4px",
    color: "#ffffff",
    '&.active': {
      color: "#66FFFF",
    },
  },
  unlikeIcon: {
    color: "#ffffff",
    '&.active': {
      color: "#66FFFF",
    },
  },
  unlikeText: {
    paddingLeft: "4px",
    color: "#ffffff",
    '&.active': {
      color: "#66FFFF",
    },
  },
  cardActions: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    padding: "12px 0",
  },
  avatarContainer: {
    position: 'relative',
    display: 'inline-block',
  },
  bunnyEars: {
    position: 'absolute',
    top: '-10px',
    left: '50%',
    transform: 'translateX(-50%)',
    width: '24px',
  },
  cardHeaderAction: {
    display: 'flex',
    alignItems: 'center',
    height: '40px ',
  },
  title: {
    marginLeft: '-8px !important',
  },
  cardHeader: {
    height: '56px',
    padding: "12px 0",
    display: 'flex',
    alignItems: 'center',
  },

  rankBox: {
    fontSize: '10px',
    color: 'black',
    fontWeight: '600',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '39px',
    height: '15px',
    backgroundColor: '#838383',
    borderRadius: '4px',
    marginRight: '16px',
  },
});



export const Post = ({
  id,
  title,
  content,
  createdAt,
  user,
  rank,
  role,
  like: initialLike,
  unlike: initialUnlike,
  mainImage,
  comments,
  commentCount,
  likeByUser
}) => {
  const classes = usePostStyles();
  const { evaluationChange } = useCommunity();
  const [like, setLike] = useState(0);
  const [isLiked, setIsLiked] = useState(false);
  const [unlike, setUnlike] = useState(0);
  const [isUnliked, setIsUnliked] = useState(false);
  const [showComments, setShowComments] = useState(false);
  const isMember = role.startsWith(ROLE.MEMBER);

  const history = useHistory();

  const walletAddress = useStoreState((state) => state.auth.walletAddress);
  const token = useStoreState((state) => state.auth.token);
  const username = user?.username ?? ellipsisInMiddle(user.walletAddress, 6, 4);

  const handleCommentButtonClick = () => {
    setShowComments(true);
  };

  const handleCloseComments = () => {
    setShowComments(false);
  };

  const handleLikeClick = async () => {

    try {
      if (isLiked) {
        await evaluationChange(id, true, -1, token);
        setLike(like - 1);
      } else {
        if (isUnliked) {
          await evaluationChange(id, false, -1, token);
          setUnlike(unlike - 1);
          setIsUnliked(!isUnliked); 
        }
        await evaluationChange(id, true, 1, token);
        setLike(like + 1);
      }
      setIsLiked(!isLiked); 
    }catch(error) {
      console.log(error);
    }
  };

  const handleDislikeClick = async () => {
    
    try {
      if (isUnliked) {
        await evaluationChange(id, false, -1, token);
        setUnlike(unlike - 1);
      } else {
        if (isLiked) {
          await evaluationChange(id, true, -1, token);
          setLike(like - 1);
          setIsLiked(!isLiked); 
        }
        await evaluationChange(id, false, 1, token);
        setUnlike(unlike + 1);
      }
      setIsUnliked(!isUnliked); 
    } catch(error) {
      console.log(error);
    }
  };

  useEffect(() => {
    setLike(initialLike);
    setUnlike(initialUnlike);
    if (likeByUser === true) {
      setIsLiked(true);
      setIsUnliked(false);
    } else if (likeByUser === false) {
      setIsLiked(false);
      setIsUnliked(true);
    } else {
      setIsLiked(false);
      setIsUnliked(false);
    }
  }, [likeByUser, id]);

  return (
    <div>
    <CommentsDialog
          isOpen={showComments}
          postId={id}
          onClose={handleCloseComments}
        />
    <Card sx={{ width: 345 }} elevation={0}>
      <CardHeader
        className={classes.cardHeader}
        avatar={
          <div className={classes.avatarContainer}>
            <Avatar 
              sx={{ width: 32, height: 32, bgcolor: "#66FFFF" }} 
              aria-label="user" 
              src={user.avatarUrl || undefined}
            >
              {username.charAt(0).toUpperCase()}
            </Avatar>
            {isMember && (
              <img
                src={"/assets/ears.png"} 
                alt="Bunny Ears"
                className={classes.bunnyEars}
              />
            )}
          </div>
        }
        title={<Typography sx={{ fontWeight: 600 }} className={classes.title} >{username}</Typography>}
        action={
          <div className={classes.cardHeaderAction}>
            <div className={classes.rankBox}>
              {isMember ? `Rank ${rank}` : 'Guest'}
            </div>
          </div>
        }
        sx={{ padding: "12px 0" }}
      />
      <CardContent sx={{ padding: "0" }}>
        <Typography
          variant="body1"
          color="text.primary"
          style={{ marginBottom: "4px" }}
        >
          {title}
        </Typography>
        <Typography variant="body2" color="text.primary" style={{ whiteSpace: 'pre-wrap' }}>
          {content}
        </Typography>
        {mainImage && (
          <img
            src={mainImage}
            alt="Post Content"
            style={{ width: '100%', marginTop: '10px', borderRadius: '16px' }}
          />
        )}
      </CardContent>
      <div className={classes.cardActions}>
        <CardActions
          disableSpacing
          style={{
            backgroundColor: "#2E2F2F",
            borderRadius: "100px",
            padding: "0",
          }}
        >
          <IconButton aria-label="like" className={classes.likeButton} onClick={handleLikeClick}>
            <ArrowUpward className={`${classes.likeIcon} ${isLiked ? 'active' : ''}`} />
            <Typography
              className={`${classes.likeText} ${isLiked ? 'active' : ''}`}
              variant="body2"
            >
              {like}
            </Typography>
          </IconButton>
          <div
            style={{ borderRight: "1px solid #474747", height: "16px" }}
          ></div>
          <IconButton
            aria-label="dislike"
            color="primary"
            className={classes.likeButton}
            onClick={handleDislikeClick}
          >
            <ArrowDownward  className={`${classes.unlikeIcon} ${isUnliked ? 'active' : ''}`} />
            <Typography
              className={`${classes.unlikeText} ${isUnliked ? 'active' : ''}`}
              variant="body2"
            >
              {unlike}
            </Typography>
          </IconButton>
        </CardActions>
        <CardActions
          disableSpacing
          style={{
            backgroundColor: "#2E2F2F",
            borderRadius: "100px",
            padding: "0",
            marginLeft: "24px",
          }}
        >
          <IconButton onClick={handleCommentButtonClick} aria-label="like" style={{ padding: "4px 12px" }}>
            <CommentRounded style={{ color: "#ffffff" }} />
            <Typography
              style={{ color: "#ffffff", paddingLeft: "4px" }}
              variant="body2"
            >
              {commentCount}
            </Typography>
          </IconButton>
        </CardActions>
      </div>
      <Typography variant="body2" color="#BBBBBB">
        {dayjs(createdAt).fromNow()}
      </Typography>
      <Divider color="#252525" style={{ marginTop: "12px" }}></Divider>
    </Card>
    </div>

  );
};
