import React from "react";
import { Route, withRouter } from "react-router-dom";

/**
 * Use to ensure users are logged out when accessing a route
 * @param Component
 * @param rest
 * @returns {Element}
 * @constructor
 */
const PublicRoute = ({ component: Component, ...rest }) => {
    // const { isAuthenticated } = useStoreState((state) => state.auth);
    return (
        <Route
            {...rest}
            render={(props) =>
                /*
                isAuthenticated ? (
                    <Redirect to="/c" />
                ) : (
                */
                <Component {...props} />
            }
        />
    );
};

export default withRouter(PublicRoute);
