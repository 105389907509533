import { thunk, action } from "easy-peasy";
import { login } from "../services/auth-service";
import history from "../util/history-util";
import get from "lodash/get";
import {
  checkLocalStorage,
  setInLocalStorage,
} from "../util/localstorage-util";

export const resetState = {
  token: null,
  isAuthenticated: false,
  walletAddress: null,
}

export const initialState = checkLocalStorage("auth", resetState);

export const authModel = {
  auth: {
    ...initialState,
    isAuthLoading: false,
    authError: "",
    authenticateUser: thunk(async (actions, payload) => {
      try {
        actions.updateIsAuthLoading(true);
        const authResponse = await login(payload);
        actions.updateAuth(authResponse);
        actions.updateIsAuthLoading(false);
        history.push("/");
      } catch (error) {
        actions.updateIsAuthLoading(false);
        if (error.response.status === 401) {
          actions.updateAuthError({ message: "invalid credentials" });
        }
        console.group("authenticateUser error");
        console.warn(
          `error caught when authenticating user -> ${JSON.stringify(
            error,
            null,
            2
          )}`
        );
        console.groupEnd();
      }
    }),
    updateWalletAddress: action((state, payload) => {
      const walletAddress = get(payload, "walletAddress", null);
      const token = get(payload, "accessToken", null);
      const isAuthenticated = get(payload, "isAuthenticated", Boolean(token));
      state.walletAddress = walletAddress;
      state.token = token;
      state.isAuthenticated = isAuthenticated;

      const currentAuthState = checkLocalStorage("auth", {});

      setInLocalStorage("auth", {
        ...currentAuthState,
        token,
        walletAddress,
        isAuthenticated
      });
    }),

    updateAuth: (state, payload) => {
      const merchant = get(payload, "merchant", "");
      const token = get(payload, "token", "");
      const user = get(payload, "user", "");
      const isAuthenticated = get(payload, "isAuthenticated", !!token);

      // store the auth state offline
      setInLocalStorage("auth", {
        isAuthenticated,
        merchant,
        token,
        user,
      });

      state.isAuthenticated = !!token;
      state.merchant = merchant;
      state.token = token;
      state.user = user;
    },
    updateIsAuthLoading: (state, payload) => {
      const loading = get(payload, "loading", false);
      state.isAuthLoading = loading;
    },
    updateAuthError: (state, payload) => {
      const errorMessage = get(payload, "message", "failure to authenticate");
      state.authError = errorMessage;
    },
    clearAuth: action((state) => {
      localStorage.removeItem("auth");
      state.token = null;
      state.isAuthenticated = false;
      state.walletAddress = null;
    }),
  },
};
