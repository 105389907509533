import { makeStyles } from "@material-ui/styles";

const useCommonButtonStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 0,
    cursor: "pointer",
    borderRadius: "1000px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "normal",
    letterSpacing: "0.42px",
    padding: "4px 12px",
    background: props => props.backgroundColor,
    color: "#161618",
    opacity: props => props.disabled ? 0.5 : 1,
  },
}));

const CommonButton = ({title, styles, type, onClick, disabled = false}) => {
  let bgColor = '#879DA0';
  if (type === 'primary') {
    bgColor = '#6FF';
  }
  const classes = useCommonButtonStyles({
    backgroundColor: bgColor,
    disabled: disabled,
  });
  return (<div className={classes.root} style={styles} onClick={onClick}>{title}</div>)
};

export default CommonButton;
