import React, { Fragment } from "react";
import NavBar from "../components/navbar-component";
import BottomTray from "./bottom-tray-component";
import { makeStyles } from "@material-ui/styles";
import {useStoreState} from "easy-peasy";

const useLayoutStyles = makeStyles({
  content: {
    minHeight: "calc(100vh - 64px)",
    width: "100%",
    display: "flex",
    justifyContent: "flex-start",
    flexDirection: "column",
    maxWidth: "345px",
    overflowY: "auto",
    "&::-webkit-scrollbar": {
      display: "none", // Chrome, Safari, Opera
    },
  },
});


export const Layout = (props) => {

  const classes = useLayoutStyles();
  const {pathname} = window.location.pathname;
  const { isAuthenticated } = useStoreState((state) => state.auth);
  return (
    <div className={classes.content}>
      <Fragment>
        <NavBar />
        {props.children}
        <BottomTray /> 
      </Fragment>
    </div>
  );
};
