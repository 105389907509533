import React, { useState, useEffect } from 'react';
import { makeStyles } from "@material-ui/styles";
import { FavoriteBorder } from "@material-ui/icons";
import { useComment } from "../hooks/comment-hook";
import { ellipsisInMiddle } from "../util/misc";
import { Avatar } from "@mui/material";
import { useStoreState } from "easy-peasy";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { ROLE } from "../constants";
dayjs.extend(relativeTime)

const useCommentCardStyles = makeStyles((theme) => ({
  commentCard: {
    marginTop: "16px",
  },
  header: {
    display: "flex",
    marginBottom: "12px",
    alignItems: 'center',
  },
  avatarContainer: {
    position: 'relative',
    marginRight: '8px',
    display: 'inline-block',
  },
  avatar: {
    width: "32px",
    height: "32px",
    bgcolor: "#66FFFF",
    borderRadius: "1000px",
  },
  userName: {
    color: "#FFF",
    textAlign: "center",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "normal",
    letterSpacing: "0.36px",
    marginRight: "8px",
  },
  timestamp: {
    color: "#BBB",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 300,
    lineHeight: "normal",
    letterSpacing: "0.24px",
  },
  like: {
    width: "16px",
    height: "16px",
    marginLeft: "auto",
    cursor: "pointer",
  },
  likeActive: {
    width: "16px",
    height: "16px",
    marginLeft: "auto",
    cursor: "pointer",
    color: "red",
  },
  comment: {
    color: "#FFF",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 300,
    lineHeight: "normal",
    letterSpacing: "0.28px",
  },
  bunnyEars: {
    position: 'absolute',
    top: '-10px',
    left: '50%',
    transform: 'translateX(-50%)',
    width: '24px',
  },

}));


const CommentCard = ({ comment }) => {

  const classes = useCommentCardStyles();
  const [isLiked, setIsLiked] = useState(false);
  const { commentLikeUnlike } = useComment();

  const { token } = useStoreState((state) => state.auth);

  const username = comment.user.username ?? ellipsisInMiddle(comment.user.walletAddress, 6, 4);
  const likeStyle = isLiked ? classes.likeActive : classes.like;
  const isMember = comment.role.startsWith(ROLE.MEMBER);

  const handleLikeClick = async () => {
    await commentLikeUnlike(token, !isLiked, comment.id);
    setIsLiked(!isLiked);
  };

  useEffect(() => {
    setIsLiked(comment.likeByUser);
  }, [comment.likeByUser]);

  return (
    <div className={classes.commentCard}>
      <div className={classes.header}>
        <div className={classes.avatarContainer}>

          <Avatar 
            sx={{ width: 32, height: 32, bgcolor: "#66FFFF" }} 
            aria-label="user" 
            src={comment.user.avatarUrl || undefined}
          >
            {username.charAt(0).toUpperCase()}
          </Avatar>
          {isMember && (
            <img
              src={"/assets/ears.png"} 
              alt="Bunny Ears"
              className={classes.bunnyEars}
            />
          )}
        </div>
        <div className={classes.userName}>{username}</div>
        <div className={classes.timestamp}>{dayjs(comment.createdAt).fromNow()}</div>
        <FavoriteBorder className={likeStyle} onClick={handleLikeClick} />
      </div>
      <div className={classes.comment}>{comment.text}</div>
    </div>
  );
}

export default CommentCard;
