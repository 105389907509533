import React from "react";
import { Route, Router, Switch, Redirect } from "react-router";

import { Layout } from "../components/layout-component";
import { ExternalLayout } from "../components/external-layout-component";
import history from "../util/history-util";
import PrivateRoute from "./PrivateRoute";
import Join from "../pages/join-page";
import Login from "../pages/login-page";
import NotFound from "../pages/not-found-page";
import Waitlist from "../pages/waitlist-page";
import Community from "../pages/community-page";
import CommunityList from "../pages/community-list-page";
import CommunityStats from "../pages/community-stats-page";
import PostNew from "../pages/post-new-page";
import ProfilePage from "../pages/profile-page";
import AvatarEdition from "../pages/avatar-edition-page";
import PublicRoute from "./PublicRoute";
import {useStoreState} from "easy-peasy";

/**
 * 👉 if authenticated routes are needed 👈
 *
 *
 *
 **/
//
// const RestrictedArea = () => {
//     return (
//         <Switch>
//             <PrivateRoute path="/c/:community" component={Community} />
//             <PrivateRoute path="/post/new" component={PostNew} />
//             <PrivateRoute path="/profile" component={ProfilePage} />
//             {/* todo implement community events list */}
//             {/*<PrivateRoute path="/c/:community/events" component={Community} />*/}
//         </Switch>
//     );
// };

export const Routes = () => {
    const token = useStoreState((state) => state.auth.token);
    return (
        <Router history={history}>
            {/* <Switch>
                <Route path="/waitlist" component={Waitlist} />
            </Switch> */}



            <Switch>
                <Route path={['/profile', '/stats/:communityId', '/c/:communityId', '/post/new', '/community/list', '/avatar/edit']}>
                    <Layout>
                        <Switch>
                            <PrivateRoute 
                                path="/stats/:communityId" 
                                render={(props) => <CommunityStats {...props} pageType="stats" />} 
                            />
                             <PrivateRoute 
                                path="/c/:communityId" 
                                render={(props) => <Community {...props} pageType="community" />} 
                            />
                            <PrivateRoute path="/profile" component={ProfilePage} />
                            <PrivateRoute path="/post/new" component={PostNew} />
                            <PrivateRoute path="/avatar/edit" component={AvatarEdition} />
                            <PrivateRoute path="/community/list" component={CommunityList} />
                            <Redirect to="/notfound" />
                        </Switch>
                    </Layout>
                </Route>
                <Route path={['/']}>
                    <ExternalLayout>
                        <Switch>
                            <PublicRoute path="/waitlist" component={Waitlist} />
                            <PublicRoute path="/join" component={Join} />
                            <PublicRoute path="/login" component={Login} />
                            <Route path="/notfound" component={NotFound} />
                            <Route exact path="/" render={() => (
                              <Redirect to="/login"/>
                            )}/>
                            <Redirect to="/notfound" />
                        </Switch>
                    </ExternalLayout>
                </Route>
            </Switch>

        </Router>
    );
};
