import React from "react";
import { useStore, useStoreActions } from "easy-peasy";
import {
  Button,
  Dialog,
  styled,
  Typography,
  Toolbar,
  AppBar,
  TextField,
  IconButton,
} from "@mui/material";
import ArrowBack from "@mui/icons-material/ArrowBack";
import AddIcon from "@mui/icons-material/Add";
import { makeStyles } from "@material-ui/styles";

const usePostStyles = makeStyles({
  createButton: {
    color: "rgba(102, 255, 255, 1)",
  },
  newPost: {
    backgroundColor: "rgba(22, 24, 24, 1)",
    display: "flex",
    flexDirection: "column",
    flex: "1 0 auto",
    padding: "14px",
  },
  postCreateTitle: {
    " &.MuiTypography-root": {
      fontWeight: "700",
    },
  },
  buttonRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
    backgroundColor: "rgba(22, 24, 24, 1)",
    paddingBottom: "16px",
  },
  submit: {
    " &.MuiButtonBase-root": {
      backgroundColor: "rgba(102, 255, 255, 1)",
      "&:hover": {
        backgroundColor: "rgba(102, 255, 255, 1)",
      },
    },
  },
  cancel: {
    " &.MuiButtonBase-root": {
      backgroundColor: "rgba(135, 157, 160, 1)",
      marginRight: "12px",
      "&:hover": {
        backgroundColor: "rgba(135, 157, 160, 1)",
      },
    },
  },
});

export const CreatePostComponent = () => {
  const classes = usePostStyles();
  const { lastSelectedCommunity } = useStore((state) => state.settings);
  const { postCreateMode } = useStore((state) => state.settings);
  const { updatePostCreateMode } = useStoreActions((dispatch) => ({
    updatePostCreateMode: dispatch.settings.updatePostCreateMode,
  }));

  const submit = () => {
    console.log("submit");
  };

  const CustomButton = styled(Button)({
    borderRadius: "100px",
    fontWeight: "600",
    color: "rgba(22, 24, 24, 1)",
    width: "100px",
    padding: "2px 6px",
    fontFamily: ["Titillium Web", "sans-serif"].join(","),
    "&:hover": {
      filter: "brightness(80%)",
    },
    transition: "all 1s",
  });

  return (
    <React.Fragment>
      <IconButton onClick={() => updatePostCreateMode(true)}>
        <AddIcon className={classes.createButton} />
      </IconButton>
      <Dialog
        fullScreen
        open={postCreateMode}
        onClose={() => updatePostCreateMode(false)}
      >
        <AppBar sx={{ position: "relative" }} elevation={0}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => updatePostCreateMode(false)}
              aria-label="close"
            >
              <ArrowBack />
            </IconButton>
            <Typography
              sx={{ ml: 2, flex: 1 }}
              textAlign={"center"}
              variant="h6"
              component="div"
              className={classes.postCreateTitle}
            >
              New Post
            </Typography>
          </Toolbar>
        </AppBar>
        <div className={classes.newPost}>
          <TextField
            id="new-post-title"
            label={
              <span style={{ fontSize: "18px", fontWeight: "600" }}>Title</span>
            }
            multiline
            shrink={true}
            maxRows={4}
            color="info"
            variant="standard"
            style={{ marginBottom: "16px" }}
          />
          <TextField
            id="new-post-body"
            label="Description"
            multiline
            color="info"
            minRows={10}
            maxRows={15}
            variant="standard"
          />
        </div>
        <div className={classes.buttonRow}>
          <CustomButton
            className={classes.cancel}
            onClick={() => updatePostCreateMode(false)}
          >
            Cancel
          </CustomButton>
          <CustomButton className={classes.submit} onClick={submit}>
            Post
          </CustomButton>
        </div>
      </Dialog>
    </React.Fragment>
  );
};
