import { action } from "easy-peasy";

export const userInitialState = {
  memberCommunities: new Map(),
  guestCommunities: new Map(),
};

export const userModel = {
  user: {
    ...userInitialState,
    updateMemberCommunities: action((state, payload) => {
      const memberCommunities = payload.memberCommunities || new Map();
      state.memberCommunities = memberCommunities;
    }),
    updateGuestCommunities: action((state, payload) => {
      const guestCommunities = payload.guestCommunities || new Map();
      state.guestCommunities = guestCommunities;
    }),
    clearUser: action((state) => {
      state.memberCommunities = new Map();
      state.guestCommunities = new Map();
    }),
  },
};
