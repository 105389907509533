import React from "react";
import ReactDOM from "react-dom";
import { StoreProvider } from "easy-peasy";
import store from "./configureStore";
import registerServiceWorker from "./serviceWorker";
import { ThemeProvider } from "@mui/material/styles";
import { Routes } from "./routes";
import { theme } from "./styles/custom-theme-style";
import "./index.css";
import { createTheme } from "@mui/material/styles";

const customTheme = createTheme(theme);

ReactDOM.render(
  <div className="App">
    <StoreProvider store={store}>
      <ThemeProvider theme={customTheme}>
        <Routes />
      </ThemeProvider>
    </StoreProvider>
  </div>,
  document.getElementById("root")
);

// Learn more about service workers in CRA: http://bit.ly/CRA-PWA
registerServiceWorker();
