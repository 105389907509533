import React from 'react';
import { Button, Select, MenuItem } from '@mui/material';
import { SORT_TYPE, SORT_PERIOD } from "../constants";

import { makeStyles } from "@material-ui/styles";
import NewReleasesIcon from '@mui/icons-material/NewReleases';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';

const useStyles = makeStyles({
  filterBarContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '10px',
    marginTop: '10px',
  },
  selectStyle: {
    width: '120px',
    height: '40px',
    fontSize: '14px !important',
    textAlign: 'center',
    '& .MuiSelect-select': {
      textAlign: 'center',
      textAlignLast: 'center',
    },
  },
  buttonStyle: {
    marginRight: '7px !important',
  },
  iconStyle: {
    marginRight: '5px',
  },
});

const FilterBar = ({ onFilterTypeChange, onFilterPeriodChange, defaultPeriod = SORT_PERIOD.MONTH }) => {
    const classes = useStyles();
    return (
        <div className={classes.filterBarContainer} >
            <div>
                <Button className={classes.buttonStyle} onClick={() => onFilterTypeChange(SORT_TYPE.NEW)}>
                    <NewReleasesIcon className={classes.iconStyle} />
                    New
                </Button>
                <Button className={classes.buttonStyle} onClick={() => onFilterTypeChange(SORT_TYPE.HOT)}>
                    <ArrowUpwardIcon className={classes.iconStyle} />
                    Top
                </Button>
            </div>
            <Select 
                defaultValue={defaultPeriod} 
                onChange={(e) => onFilterPeriodChange(e.target.value)}
                className={classes.selectStyle}
            >
                <MenuItem value= {SORT_PERIOD.TODAY} >Today</MenuItem>
                <MenuItem value= {SORT_PERIOD.WEEK} >This Week</MenuItem>
                <MenuItem value= {SORT_PERIOD.MONTH} >This Month</MenuItem>
            </Select>
        </div>
    );
};

export default FilterBar;