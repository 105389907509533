import React from "react";
import { Redirect, Route, withRouter } from "react-router-dom";
import { useStoreState } from "easy-peasy";

const PrivateRoute = ({ component: Component, render: Render, ...rest }) => {
  const token = useStoreState((state) => state.auth.token);

  return (
    <Route
      {...rest}
      render={(props) =>
        !!token ? (
          Render ? Render(props) : <Component {...props} />
        ) : (
          <Redirect to="/login" />
        )
      }
    />
  );
};

export default withRouter(PrivateRoute);
