import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import { makeStyles, styled } from "@material-ui/styles";
import CommentCard from './comment-card';
import useSWR from 'swr';
import { fetcher } from '../util/fetcher';
import axios from 'axios';
import { useStoreState } from 'easy-peasy';
import { withRouter } from 'react-router-dom';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const background = '#161818 !important';
const useCommentsDialogStyles = makeStyles({
  root: {
    '& [role="dialog"]' : {
      background,
      overflowY: "auto",
    }
  },
  toolbar: {
    background,
  },
  list: {
    background,
  },
  title: {
    color: "#BBB",
    fontFamily: 'Titillium Web',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: 'normal',
    letterSpacing: '0.36px',
    textAlign: 'left',
    width: '100%',
  },
  body: {
    marginLeft: '24px',
    marginRight: '24px',
    paddingTop: '64px',
    paddingBottom: '64px'
  },
  footer: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    width: '100%',
    padding: '20px 24px 32px',
    background,
  },
  inputWrapper: {
    padding: '12px',
    border: '1px solid #404040',
    borderRadius: '12px',
    display: 'flex',
    '& input': {
      width: "100%",
      height: "100%",
      border: "none",
      outline: "none",
      backgroundColor: "transparent",
      color: '#A2A2A2',
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: 500,
      lineHeight: '28px',
      grow: 1,
    },
  },
  sender: {
    width: '20px',
    height: '20px',
    marginLeft: '12px',
    cursor: 'pointer'
  },
  noComments: {
    color: '#BBB',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 300,
    lineHeight: '28px',
    letterSpacing: '0.28px',
    textAlign: 'center',
  },
  appBar: {
  }
});

const CloseButton = styled(IconButton)({
  marginLeft: 'auto !important'
});

function CommentsDialog({ postId, isOpen, onClose }) {

  const classes = useCommentsDialogStyles();
  const { token, walletAddress } = useStoreState((state) => state.auth);

  let comments = [];
  const { data, mutate } = useSWR(postId && isOpen ? `${process.env.REACT_APP_HOST_ORIGIN}/v1/comments/post/${postId}/${walletAddress}?offset=0&limit=10` : null, fetcher);
  comments = data || [];

  /*
  // data for debug
  const comment = {
    user: {
      avatar: '/assets/test-avatar.png',
      username: 'Lucypop',
    },
    updatedAt: '2023-12-20',
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut et massa mi. Aliquam in hendrerit urna.',
  };

  comments = new Array(10).fill(comment);
  */

  const [open, setOpen] = React.useState(isOpen);

  const [currentComment, setCurrentComment] = React.useState('');
  const onInputComment = React.useCallback((e) => {
    setCurrentComment(e.target.value);
  }, []);

  React.useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  const handleClose = () => {
    setOpen(false);
    // remove postId to prevent SWR from repeatedly re-fetching
    postId = null;
    onClose();
  };

  const onSubmit = React.useCallback(async () => {
    if (!postId || !currentComment) return;
    await axios.request({
      method: 'POST',
      url: `${process.env.REACT_APP_HOST_ORIGIN}/v1/comments/create`,
      data: {
        text: currentComment,
        postId,
      },
      headers: {
        'Authorization': `Bearer ${token}`
      },
    });
    setCurrentComment('');
    mutate();
  }, [currentComment, mutate, postId, token]);

  const handleKeyDown = (event) => {
    if(event.key === 'Enter'){
      onSubmit();
    }
  }

  return (
    <Dialog
      className={classes.root}
      fullScreen
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <AppBar className={classes.appBar} sx={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        width: '100%',
        height: '64px',
        boxShadow: 'none',
        backgroundImage: 'none',
      }}>
        <Toolbar className={classes.toolbar} sx={{ flexDirection: 'column' }}>
          <CloseButton
            edge="end"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </CloseButton>
          <div className={classes.title}>
            Comments
          </div>
        </Toolbar>
      </AppBar>
      <div className={classes.body}>
        {comments.map((comment, index) => {
          return (
            <CommentCard comment={comment} key={index} />
          );
        })}
        {comments.length === 0 && (
          <div className={classes.noComments}>
            No comments yet
          </div>
        )}
      </div>
      <div className={classes.footer}>
        <div className={classes.inputWrapper}>
          <input placeholder="Add your comment here..." type='text' value={currentComment} onChange={onInputComment} onKeyDown={handleKeyDown}/>
          <img src='/assets/tabler_send.svg' alt='submit' className={classes.sender} onClick={onSubmit} />
        </div>
      </div>
     </Dialog>
  
  );
}

export default withRouter(CommentsDialog);