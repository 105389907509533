import { useEffect, useState } from "react";
import { getContractViewer } from "../util/contractHelper";
import BigNumber from "bignumber.js";

const useKeysBought = (walletAddress, keysSubject) => {
  const [keysBought, setKeysBought] = useState(0);

  useEffect(() => {
    if (!walletAddress || !keysSubject) return;
    getContractViewer().keysBalance(keysSubject, walletAddress).then((res) => {
      setKeysBought((new BigNumber(res)).toNumber());
    });
  }, [keysSubject, walletAddress]);

  return keysBought;
};

export default useKeysBought;
