import { ethers } from "ethers";
import { CommunityKeysV1__factory, CommunityKeysV1 } from "nimble-community-contracts-sdk";

let contractViewer: CommunityKeysV1 = null;
const provider = new ethers.JsonRpcProvider(process.env.REACT_APP_CONTRACT_RPC_URL);
const contractAddress = process.env.REACT_APP_COMMUNITY_CONTRACT_ADDRESS;

export const getContractViewer = () => {
  if (!contractViewer) {
    contractViewer = CommunityKeysV1__factory.connect(contractAddress, provider);
  }

  return contractViewer;
}
