import React, { useCallback, useEffect } from "react";
import { withRouter } from "react-router-dom";
import {makeStyles} from "@material-ui/styles";
import ConnectWalletButton from "../components/connect-wallet-component";
import { useStoreActions, useStoreState } from "easy-peasy";
import history from "../util/history-util";
import axios from "axios";
import Dialog from '@mui/material/Dialog';
import { DialogContent, DialogTitle, ListItemButton, List, ListItemText, DialogActions } from "@mui/material";
import CommonButton from "../components/CommonButton";

/**
 * The community main page, which displays a list of Posts
 * @returns {JSX.Element}
 * @constructor
 */

const useNavStyles = makeStyles({
  inputWrapper: {
    marginBottom: "20px",
  },
  label: {
    color: "white",
    margin: "8px 0 16px",
    fontWeight: "bold",
  },

  submit: {
    paddingTop: "135px",
  },
  backgroundBlur: {
    background: "radial-gradient(circle, rgba(102,255,255,0.2) 0%, rgba(22,24,24,1) 40%)",
    backgroundSize: 'cover',
    width: '100%',
    height: '100vh',
    backgroundPositionY: '-20vh',
    backgroundPositionX: 'center',
    backgroundRepeat: 'no-repeat',
    position: 'absolute',
    zIndex: '0',
  },
  formWrap: {
    minWidth: '300px',
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center'
  },
  form: {
    width: '100%',
    maxWidth: '320px',
  },
  waitlistForm: {
    width: '100%',
    paddingBottom: '48px',
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '500px',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: '1',
  },
  graphic: {
    backgroundImage: "url('/assets/nimble-graphic.png')",
    background: "no-repeat center center",
    backgroundSize: "contain",
    width: "100%",
    height: '200px',
    marginTop: '120px'
  },
  copyText: {
    fontWeight: '400',
    fontSize: '1em',
    textAlign: 'center',
    color: '#66FFFF',
    marginTop: '0'
  },
  inputField: {
    width: "100%",
    height: "48px",
    "& .MuiInputBase-input": {
      borderRadius: "12px",
      backgroundColor: "rgba(0, 0, 0, 0.7)",
      color: "#bbbbbb",
    },
    "& .MuiInputLabel-root": {
      color: "#bbbbbb",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "transparent",
      },
      "&:hover fieldset": {
        borderColor: "#bbbbbb",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#bbbbbb",
      },
    },
  },
  dialog: {
    height: "auto",
    maxHeight: "50vh",
  }
});

const Login = () => {
  const classes = useNavStyles();
  // todo after the user has logged in, use the updateAvailableCommunities action to dispatch
  // which communities the user can access
  const [communities, setCommunities] = React.useState([]);
  const selectedCommunity = useStoreState((state) => state.settings.currentSelectedCommunity);
  const setSelectedCommunity = useStoreActions((actions) => actions.settings.updateSelectedCommunity);

  // const [selectedCommunity, setSelectedCommunity] = React.useState();
  const isToSelectCommunity = communities.length > 1;

  useEffect(() => {
    if (communities.length === 1) {
      setSelectedCommunity(communities[0]);
    }
  }, [communities, setSelectedCommunity]);

  const goCommunity = useCallback(() => {
    if (selectedCommunity) {
      // TODO: push to the coresponding community page
      history.push(`/c/${selectedCommunity.id}`);
    }
  }, [selectedCommunity]);

  const { isAuthenticated, walletAddress, token } = useStoreState((state) => state.auth);
  if (walletAddress && token && !isAuthenticated) {
    history.push('/join');
  } else if (isAuthenticated && communities.length === 1) {
    goCommunity();
  }

  useEffect(() => {
    if (isAuthenticated) {
      axios.request({
        method: 'GET',
        url: `${process.env.REACT_APP_HOST_ORIGIN}/v1/community/user/community/partial`,
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
      .then(({ data }) => {
        setCommunities(data);
      })
      .catch((error) => {
        clearAuth();
        console.error("An error occurred:", error);
      });
    }
  }, [isAuthenticated, token]);

  const clearAuth = useStoreActions(
    (dispatch) => dispatch.auth.clearAuth
  );

  const handleDialogClose = useCallback(() => {
    setCommunities([]);
    clearAuth();
  }, [clearAuth]);

  return (
    <div className={classes.formWrap}>
      <div className={classes.backgroundBlur}></div>
      <div className={classes.waitlistForm}>
        <div className={classes.graphic}></div>
        <h4 className={classes.copyText}>Welcome to Nimbus<br/>To get started, connect your wallet</h4>
        {!walletAddress && <form noValidate className={classes.form}>
          <ConnectWalletButton className='connectWalletLogin' />
        </form>}
      </div>
      <Dialog
        open={isToSelectCommunity}
        onClose={handleDialogClose}
        scroll={'paper'}
        className={classes.dialog}
      >
        <DialogTitle id="scroll-dialog-title">Select the community</DialogTitle>
        <DialogContent dividers>
          <List component={'div'} aria-label={'main mailbox folders'}>
            {communities.map((community, i) => {
              return (
                <ListItemButton
                  key={i}
                  selected={selectedCommunity?.id === communities[i]?.id }
                  onClick={() => setSelectedCommunity(communities[i]) }
                >
                  <ListItemText primary={communities[i].communityName} />
                </ListItemButton>
              );
            })}
          </List>
        </DialogContent>
        <DialogActions>
          <CommonButton onClick={handleDialogClose} title={'Cancel'} />
          <CommonButton onClick={goCommunity} title={'Go'} type={'primary'} />
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default withRouter(Login);
