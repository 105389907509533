import React, {useState} from "react";
import { Link, withRouter } from "react-router-dom";
import { makeStyles } from "@material-ui/styles";
import FeedIcon from "@mui/icons-material/Feed";
import GroupsIcon from '@mui/icons-material/Groups';
import AccountCircle from "@mui/icons-material/AccountCircle";
import EventIcon from '@mui/icons-material/Event';

import { compose } from "../util/functional-util";
import history from "../util/history-util";
import { BottomNavigation, BottomNavigationAction } from "@material-ui/core";
import { Paper } from "@mui/material";
import { useStoreState } from "easy-peasy";

const useNavStyles = makeStyles({
  bottomNavButton: {
    color: "rgba(133, 167, 167, 1)",
    fontWeight: "600",
  },
  bottomNavSelected: {
    color: "rgba(102, 255, 255, 1)",
  },
  bottomNavRoot: {
    backgroundColor: "rgba(37, 40, 41, 1)",
  },
});

const BottomTray = () => {
  const pathname = window.location.pathname;
  const token = useStoreState((state) => state.auth.token);
  const selectedCommunity = useStoreState((state) => state.settings.currentSelectedCommunity);

  const classes = useNavStyles();

  /**
   * get logged in state from the session store when available
   * @type {boolean}
   */

  if (!!token) {
    return (
      <Paper
        sx={{ position: "fixed", bottom: 0, left: 0, right: 0, 'z-index': 10 }}
        elevation={3}
      >
        <BottomNavigation
          showLabels={true}
          className={classes.bottomNavRoot}
          onChange={(event, newValue) => {
            history.push(newValue);
          }}
          sx={{
            "& .MuiBottomNavigationAction-root, .Mui-selected, svg": {
              color: "rgba(102, 255, 255, 1)",
            },
          }}
        >
          <BottomNavigationAction
            className={classes.bottomNavButton}
            component={Link}
            value={`/c/${selectedCommunity?.id}`}
            to={`/c/${selectedCommunity?.id}`}
            label="Community"
            icon={<FeedIcon />}
          />
          {/* todo implement community feed when shares can be purchased */}
          {/*<BottomNavigationAction*/}
          {/*    className={classes.bottomNavButton}*/}
          {/*    component={Link}*/}
          {/*    value={"/c/" + lastSelectedCommunity + "/feed"}*/}
          {/*    to={"/c/" + lastSelectedCommunity+ "/feed"}*/}
          {/*    label="Community Events"*/}
          {/*    icon={<CommunityIcon/>}*/}
          {/*/>*/}
          <BottomNavigationAction
            className={classes.bottomNavButton}
            onClick={() => {history.push(`/stats/${selectedCommunity?.id}`)}}
            label="Events"
            icon={<EventIcon />}
          />
          <BottomNavigationAction
            className={classes.bottomNavButton}
            onClick={() => {history.push('/community/list')}}
            label="Communities"
            icon={<GroupsIcon />}
          />
          <BottomNavigationAction
            className={classes.bottomNavButton}
            onClick={() => {history.push('/profile')}}
            label="Account"
            icon={<AccountCircle />}
          />
        </BottomNavigation>
      </Paper>
    );
  }
  return <div></div>;
};

export default compose(withRouter)(BottomTray);
