import { Button } from "@mui/material";
import { makeStyles } from "@material-ui/styles";
import React from "react";
import { withRouter } from "react-router-dom";
import { compose } from "../util/functional-util";

const alertStyles = makeStyles({
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.7)",
    backdropFilter: "blur(8px)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 1000,
  },
  alertBox: {
    width: "100%",
    maxWidth: "300px",
    height: "180px",
    backgroundColor: "#161818",
    border: "0.5px solid #66FFFF",
    padding: "20px",
    borderRadius: "20px",
    boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 1001,
  },
  alertBoxWrap: {
    padding: "0 12px",
    width: "100%",
    display: "flex",
    justifyContent: "center"
  },
  message: {
    fontSize: "18px",
    color: "white",
    width: "100%",
    textAlign: "center",
    marginBottom: "20px",
  },

  closeButton: {
    width: "100%",
    height: "48px",
    backgroundColor: "#66FFFF !important",
    color: "#161818 !important",
    borderRadius: "1000px !important",
    padding: "10px 60px",
    fontSize: "18px !important",
    textTransform: "none",
    fontWeight: "bold !important",
    boxShadow: "none",
    "&:hover": {
      backgroundColor: "#00D4D4",
      boxShadow: "none",
    },
  },
});

const AlertComponent = ({ isOpen, title, message, onClose }) => {
  const classes = alertStyles();
  if (!isOpen) return null;
  return (
      <div className={classes.overlay}>
        <div className={classes.alertBoxWrap}>
          <div className={classes.alertBox}>
            <div>
              <p className={classes.message}>{message}</p>
            </div>
            <Button type="button" className={classes.closeButton} onClick={onClose}>
              Close
            </Button>
          </div>
        </div>
      </div>
  );
};

export default compose(withRouter)(AlertComponent);
