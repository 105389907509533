import axios from "axios";
import dayjs from "dayjs";
import { SORT_PERIOD } from "../constants";

export const getSortedPostByCommunityId = async (communityId, filterType, filterPeriod, postNum, token, userId) => {

    const endDate = dayjs();

    let startDate;
    switch(filterPeriod) {
        
        case SORT_PERIOD.TODAY:
            startDate = dayjs().subtract(1, 'day');
            break;
        case SORT_PERIOD.WEEK:
            startDate = dayjs().subtract(1, 'week');
            break;
        case SORT_PERIOD.MONTH:
            startDate = dayjs().subtract(1, 'month');
            break;
        default:
            startDate = dayjs().subtract(1, 'day');
    }

    const response = await fetch(
        `${process.env.REACT_APP_HOST_ORIGIN}/v1/post/community/${communityId}/${userId}?offset=${postNum}&sortBy=${filterType}&startDate=${startDate.toISOString()}&endDate=${endDate.toISOString()}`,
        {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + token,
            },
        }
    );
    
    return await response.json();
};

export const changeEvaluationById = async (id, likeOrUnlike, evaluation, token) => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_HOST_ORIGIN}/v1/post/like-unlike`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + token,
                },
                body: JSON.stringify({
                    id: id,
                    like: likeOrUnlike,
                    status: evaluation,
                }),
            }
        );

        const data = await response.json();

        if (data.statusCode && data.statusCode >= 400) {
            console.error("Error:", data.message);
            throw new Error(data.message);
        }
        return data;

    } catch (error) {
        console.error('There has been a problem with your fetch operation:', error);
        throw error;
    }

};

export const createInviteCode = async (communityId, token) => {
    const response = await fetch(
        `${process.env.REACT_APP_HOST_ORIGIN}/v1/invite/create-invite-code`,
        {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + token,
            },
            body: JSON.stringify({
                communityId: communityId
            })
        }
    );


    if (response.ok) {
        // we return a text response with the status code
        return await response.text();
    } else {
        return await response.json();
    }
};

export const postCommentLikeUnlike = async (token, like, commentId) => {
    const response = await fetch(
        `${process.env.REACT_APP_HOST_ORIGIN}/v1/comments/like-unlike`,
        {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + token,
            },
            body: JSON.stringify({
                like: like,
                id: commentId,
            })
        }
    );


    if (response.ok) {
        // we return a text response with the status code
        return await response.text();
    } else {
        return await response.json();
    }
};

export const getCommunityDetails = async (token, offset, limit) => {
    const { data } = await axios({
        method: 'GET',
        url: `${process.env.REACT_APP_HOST_ORIGIN}/v1/community/user/community`,
        headers: {
            'Authorization': `Bearer ${token}`
        },
        params: {
            offset: offset,
            limit: limit
        }
    });

    const communityData = data.map((community) => {
        return {
            id: community.id,
            name: community.communityName,
            role: community.role === 'memberWithJoinAndAccount' ? 'member' : community.role,
            postCount: community.postCount,
            members: community.memberCount,
            walletAddress: community.walletAddress,
        };
    });

    return communityData;
};

export const getEventsByCommunityId = async (communityId, token, offset, limit) => {
    try {
        const { data } = await axios({
            method: 'GET',
            url: `${process.env.REACT_APP_HOST_ORIGIN}/v1/community/user-actions/${communityId}/actions/summary`,
            params: {
                offset: offset,
                limit: limit
            },
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        return data;
    } catch (error) {
        console.error('Error fetching user action summary:', error);
        throw error;
    }
};
