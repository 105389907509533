export const theme = {
  root: {
    width: '100%'
  },
  palette: {
    mode: "dark",
    primary: {
      main: "#66FFFF",
    },
    text: {
      primary: "#ffffff",
      secondary: "#BBBBBB",
    },
    background: {
      paper: "rgba(22, 24, 24, 1)",
      default: "rgba(22, 24, 24, 1)",
    },
  },
  typography: {
    fontFamily: ["Titillium Web", "sans-serif"].join(","),
    button: {
      textTransform: "none",
    },
    variant: {
      body1: {
        fontSize: "12px",
      },
      body2: {
        fontSize: "10px",
      },
    },
  },
  components: {
    MuiBottomNavigation: {
      backgroundColor: "#000000",
    },
    MuiInputBase: {
      color: "#ffffff",
      borderColor: "#ffffff",
    },
  },
  props: {
    MuiOutlinedInput: {
      notched: false,
    },
    MuiInputLabel: {
      shrink: true,
    },
  },
};
