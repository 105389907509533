export const DISCORD_HANDLE = {
    MIN: 2,
    MAX: 32,
};

export const TRANSACTION = {
    BUY: 'buy',
    SELL: 'sell',
    QUIT: 'quit',
    GAS_LIMIT: 2_000_000,
    MAX_TRANSACTION_AMOUNT: 10000,
};

export const NUMBER_CONSTANTS = {
    DECIMAL_BASE: 10,
};

export const ERROR_CODE = {
    InvalidInput: 100,
    NotFound: 404,
    ServerError: 500,
    Unauthorized: 401,
    NO_ERROR: 1
};

export const SORT_TYPE = {
    NEW: 'createdAt',
    HOT: 'hot',
};

export const SORT_PERIOD = {
    TODAY: 'today',
    WEEK: 'week',
    MONTH: 'month',
};

export const ROLE = {
    MEMBER: 'member',
    GUEST: 'guest',
    NOBODY: 'nobodyWithAccount'
};

export const JOIN_STEP = {
    VALIDATE_CODE: 'validateCode',
    SELECT_COMMUNITY: 'selectCommunity',
    ENTER_DETAIL: 'enterDetail'
};

export const CURRENCY = {
    ETH: 'ETH',
    MATIC: 'MATIC'
};

export const COMMUNITY_NAME = {
    CORNERMARKET: 'CornerMarket',
    NIMBLE: 'Nimble'
};


