import React from "react";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import {
  Avatar,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Typography,
} from "@mui/material";

dayjs.extend(relativeTime)

export const Event = ({
  commonUser,
  message,
  createdAt
}) => {

  return (
    <div>
    <Card sx={{ width: 345 }} elevation={0}>
      <CardHeader
        avatar={<Avatar sx={{ width: '32px', height: '32px', bgcolor: "#66FFFF" }} aria-label="user" src={commonUser.avatarUrl || undefined} >
          {commonUser.username.charAt(0).toUpperCase()}
          </Avatar>}
        title={<Typography sx={{ fontWeight: 600 }}>{commonUser.username}</Typography>}
        sx={{ padding: "12px 0" }}
      />
      
      <CardContent sx={{ padding: "0" }}>
        <Typography
          variant="body1"
          color="text.primary"
          style={{ marginBottom: "4px" }}
        >
          {message}
        </Typography>
      </CardContent>
      <Typography variant="body2" color="#BBBBBB">
        {dayjs(createdAt).fromNow()}
      </Typography>
      <Divider color="#252525" style={{ marginTop: "12px" }}></Divider>
    </Card>
    </div>
  );
};
