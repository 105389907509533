import axios from "axios";
import { DISCORD_HANDLE } from "../constants";

export const handleAuthError = (error, clearAuth) => {
    switch (error.statusCode) {
        case 401:
            clearAuth()
        default:
            break
    }
}

export const login = async ({ username, password }) => {
  try {
    const authenticatedResponse = await axios({
      url: `${process.env.REACT_APP_API_URL}/authenticate`,
      method: "post",
      data: {
        email: username,
        password,
      },
    });
    return authenticatedResponse.data;
  } catch (error) {
    throw error;
  }
};

export const getLoginSignatureMessage = async () => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_HOST_ORIGIN}/v1/community/login/message`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({}),
      }
    );

    const data = await response.json();
    if (data.error) {
      console.error("Error:", data.message);
      throw new Error(data.message);
    }
    return data.message;
  } catch (error) {
    console.error(
      "Network or parsing error:",
      error.message || "An unknown error occurred"
    );
    throw new Error(error.message);
  }
};

export const getSignupSignatureMessage = async () => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_HOST_ORIGIN}/v1/community/signup-message-generation`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({}),
      }
    );

    const data = await response.json();
    if (data.error) {
      console.error("Error:", data.message);
      throw new Error(data.message);
    }
    return data.message;
  } catch (error) {
    console.error(
      "Network or parsing error:",
      error.message || "An unknown error occurred"
    );
    throw new Error(error.message);
  }
};

export const getWaitlistSignatureMessage = async () => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_HOST_ORIGIN}/v1/community/waitlist/message`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({}),
      }
    );
    const data = await response.json();
    if (data.error) {
      console.error("Error:", data.message);
      return null;
    }
    return data.message;
  } catch (error) {
    console.error(
      "Network or parsing error:",
      error.message || "An unknown error occurred"
    );
    return null;
  }
};

export const getWalletConnectionSignatureMessage = async () => {
  const response = await fetch(
    `${process.env.REACT_APP_HOST_ORIGIN}/v1/community/messages`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        type: 'wallet'
      }),
    }
  );
  const data = await response.json();
  // TODO: handle error here
  if (data.error) {
    console.error("Error:", data.message);
    return null;
  }
  return data.message;
};

export const connectWalletWithSignature = async (
  message,
  signature,
  walletAddress
) => {
  const { data } = await axios.request({
    method: "POST",
    url: `${process.env.REACT_APP_HOST_ORIGIN}/v1/community/wallet/validate`,
    data: {
      message: message,
      signature: signature,
      walletAddress: walletAddress,
    },
  });
  /*
  const response = await fetch(
    `${process.env.REACT_APP_HOST_ORIGIN}/v1/community/wallet/validate`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        message: message,
        signature: signature,
        walletAddress: walletAddress,
      }),
    }
  );

  const data = await response.json();
  */

  return data;
};

export const loginWithSignature = async (message, signature, walletAddress) => {
  const response = await fetch(
    `${process.env.REACT_APP_HOST_ORIGIN}/v1/community/login/signature`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        message: message,
        signature: signature,
        walletAddress: walletAddress,
      }),
    }
  );
  const data = await response.json();
  if (data.error) {
    console.error("Error:", data.message);
    return null;
  }

  return data.accessToken;
};

export const getUserInfo = async (accessToken) => {
  const response = await fetch(
    `${process.env.REACT_APP_HOST_ORIGIN}/v1/community/user/info`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
      },
    }
  );
  const data = await response.json();
  if (data.error) {
    console.error("Error:", data.message);
    throw new Error(data.message);
  }

  return data;
};

export const signupAndJoinCommunity = async (inviteCode, communityId, username, discordHandle, accessToken) => {
  const response = await fetch(
    `${process.env.REACT_APP_HOST_ORIGIN}/v1/community/commonuser`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
      },
      body: JSON.stringify({
        communityId: communityId,
        username: username,
        discordHandle: discordHandle,
        inviteCode,
      }),
    }
  );

  const data = await response.json();

  if (data.error) {
    console.error("Error:", data.message);
    throw new Error(data.message);
  }
  return data.accessToken;
};

export const updateUserInfo = async (
  accessToken,
  username,
  discordHandle,
  email
) => {
  const response = await fetch(
    `${process.env.REACT_APP_HOST_ORIGIN}/v1/community/user/info`,
    {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
      },
      body: JSON.stringify({
        username: username,
        discordHandle: discordHandle,
        email: email,
      }),
    }
  );

  const data = await response.json();

  if (data.error) {
    console.error("Error:", data.message);
    throw new Error(data.message);
  }

  return data;
};

export const joinCommunity = async (accessToken, inviteCode) => {
  const response = await fetch(
    `${process.env.REACT_APP_HOST_ORIGIN}/v1/community/join`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
      },
      body: JSON.stringify({ inviteCode: inviteCode }),
    }
  );

  const data = await response.json();

  if (data.error) {
    console.error("Error:", data.message);
    return null;
  }

  return data;
};

export const checkUserAvailability = async (discordHandle, username, walletId) => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_HOST_ORIGIN}/v1/community/user/availability`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    discordHandle: discordHandle,
                    username: username,
                    walletId: walletId
                }),
            }
        );
        const data = await response.json();

        if (data.error) {
            throw new Error(data.message);
        }
        return data;
    } catch (error) {
        throw error;
    }

};

export const addUserIntoWaitlist = async (discordHandle, email, token) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_HOST_ORIGIN}/v1/community/waitlist`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify({ discordHandle: discordHandle, email: email }),
      }
    );
  
    const data = await response.json();
  
    if (data.error) {
      console.error(data.message);
  
      if (data.message.includes("already exists in the waitlist")) {
        throw new Error("You have already joined into our waitlist. Thanks!");
      }
      throw new Error(data.message);
    }
    return data;
  } catch (error) {
    throw error;
  }
  
};

export const isValidDiscordUsername = (discordHandle) => {
  const minLength = DISCORD_HANDLE.MIN;
  const maxLength = DISCORD_HANDLE.MAX;
  const lowerCaseUsername = discordHandle.toLowerCase();
  const disallowedKeywords = ['discord', 'admin', 'moderator', 'staff', 'system'];
  if (discordHandle.length < minLength || discordHandle.length > maxLength) {
    throw new Error('Username must be between 2 and 32 characters long.');
  }

  if (!/^[a-z0-9._]+$/.test(discordHandle)) {
    throw new Error('Username contains invalid characters. Only lowercase Latin characters, numbers, underscores, and periods are allowed.');
  }

  if (discordHandle.includes('..')) {
    throw new Error('Username cannot contain consecutive period characters.');
  }

  for (const keyword of disallowedKeywords) {
    if (lowerCaseUsername.includes(keyword)) {
      throw new Error(`Username cannot include the word "${keyword}".`);
    }
  }

  if (/^(discord|staff)[0-9]*$/.test(discordHandle.toLowerCase())) {
    throw new Error('Username cannot impersonate Discord staff or system messages.');
  }
  
  return true;
};

export const isValidEmailAddress = (email) => {
  if (!email) return false;
  const length = email.length;
  const emailRegex =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (!emailRegex.test(email) || length > 100) {
    return false;
  }
  return true;
};

export const checkInviteCodeValidation = async (inviteCode, accessToken, communityId) => {
  const { data } = await axios.request({
    method: 'POST',
    url: `${process.env.REACT_APP_HOST_ORIGIN}/v1/invite/validate`,
    data: {
      inviteCode,
      communityId
    },
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + accessToken,
    },
  });
  return data;
};

export const getInviteCodeDetail = async (inviteCode, accessToken) => {
  const { data } = await axios.request({
    method: 'GET',
    url: `${process.env.REACT_APP_HOST_ORIGIN}/v1/invite/invite-code/${inviteCode}`,
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + accessToken,
    },
  });

  return data;
};

export const getCommunitySummary = async (communityId, token) => {
  const response = await fetch(
    `${process.env.REACT_APP_HOST_ORIGIN}/v1/community/${communityId}/summary`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
          "Authorization": "Bearer " + token,
      },
    }
  );

  const data = await response.json();

  if (data.error) {
    console.error("Error:", data.message);
    throw new Error(data.message); 
  }

  return data;
};

export const getUserSummaryData = async (token) => {
  const response = await fetch(
    `${process.env.REACT_APP_HOST_ORIGIN}/v1/community/user/info/partial`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
          "Authorization": "Bearer " + token,
      },
    }
  );

  const data = await response.json();

  if (data.error) {
    console.error("Error:", data.message);
    throw new Error(data.message); 
  }

  return data;
};
