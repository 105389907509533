import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { Paper } from "@mui/material";
import { useStoreActions, useStoreState } from "easy-peasy";
import { makeStyles } from "@material-ui/styles";
import InfiniteScroll from "react-infinite-scroll-component";
import CommunityDetailCard from "../components/community-detail-card-component";
import { getUserInfo } from "../services/auth-service";
import { getCommunityDetails } from "../services/community-service";

/**
 * The community main page, which displays a list of communities
 * @returns {JSX.Element}
 * @constructor
 */

const useCommunityListStyles = makeStyles({
    title: {
        font: '16px',
        fontWeight: 'bold',
        paddingTop: '30px',
        paddingBottom: '20px',
    },
    communityList: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "center",
    },
    communityListWrap: {
        maxWidth: "345px",
        marginBottom: "80px",
        width: "100%",
        paddingTop: '50px',
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
        " &.MuiPaper-root": {
            backgroundColor: "transparent",
            color: "#ffffff"
        },
    },
});

const CommunityList = (props) => {
    const classes = useCommunityListStyles();
    const clearAuth = useStoreActions((dispatch) => dispatch.auth.clearAuth);
    const token = useStoreState((state) => state.auth.token);
    const [communities, setCommunities] = useState([]);
    const [price, setPrice] = useState([]);

    const [offset, setOffset] = useState(0);
    const [limit, setLimit] = useState(10);
    const [hasMore, setHasMore] = useState(true);

    const reloadCommunities = async () => {
        try {
            const communityData = await getCommunityDetails(token, 0, limit);
            if (communityData.length > 0) {
                setCommunities([...communityData]);
                setOffset(limit);
                setHasMore(true);
            } else {
                setCommunities([]);
                setOffset(0);
                setHasMore(false);
            }
        } catch (error) {
            clearAuth();
        }
    };

    const fetchCommunityData = async () => {
        try {
            const communityData = await getCommunityDetails(token, offset, limit);
            if (communityData.length > 0) {
                setCommunities([...communities, ...communityData]);
                setOffset(offset + limit);
            } else {
                setHasMore(false);
            }
        } catch (error) {
            clearAuth();
        }
    }

    useEffect(() => {
        getUserInfo(token).catch(() => {
            clearAuth();
        });

        window.scrollTo(0, 0);
        fetchCommunityData();
    }, []);

    const loadMoreData = () => {
        if (hasMore) {
            fetchCommunityData();
        }
    };
    return (
        <Paper elevation={0} className={classes.communityListWrap}>
            <div className={classes.title}>Community List</div>
            <InfiniteScroll
                pageStart={0}
                dataLength={communities.length}
                hasMore={hasMore}
                next={loadMoreData}
            >
                <div className={classes.communityList}>
                    {communities.map((community, index) => (
                        <CommunityDetailCard key={index} {...community} onActionComplete={reloadCommunities} />
                    ))}
                </div>
            </InfiniteScroll>
        </Paper>
    );
};

export default withRouter(CommunityList);
