import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/styles";
import Button from '@material-ui/core/Button';
import MembershipTransactionPopup from "./membership-transaction-component"; 
import JoinPopup from "./join-popup-component";
import { useCurrentSharePrice } from "../hooks/useCurrentSharePrice";
import { getContractViewer } from "../util/contractHelper";
import { TRANSACTION, NUMBER_CONSTANTS, ROLE, COMMUNITY_NAME, CURRENCY } from "../constants"; 
import { useStoreActions, useStoreState } from "easy-peasy";
import history from "../util/history-util";

const useStyles = makeStyles({
  communityCard: {
    background: 'transparent',
    border: '0.5px solid #66FFFF',
    width: '345px',
    borderRadius: '10px',
    color: 'white',
    padding: '10px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '10px',
  },
  communityName: {
    color: '#66FFFF',
    fontSize: '16px',
    fontWeight: 'bold',
  },
  role: {
    background: '#66FFFF',
    color: '#000',
    fontSize: '12px',
    borderRadius: '5px',
    padding: '3px 6px',
  },
  statsSection: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  statsBlock: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  statsTitle: {
    fontSize: '12px',
    color: 'white',
  },
  statsValue: {
    fontSize: '16px',
    fontWeight: 'bold',
  },
  buttonsContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  button: {
    marginTop: '6px',

    width : "69px",
    height: "26px",
    borderRadius: '1000px',
    color: 'black',
    fontSize: '12px',
    fontWeight: 'bold',
    textTransform: 'none',

    '&.buy': {
      backgroundColor: '#66FFFF',
    },
    '&.join': {
      backgroundColor: '#66FFFF',
    },
    '&.sell': {
      backgroundColor: '#E5F775',
    },
    '&.quit': {
      backgroundColor: '#FF275A',
    },
  },
});

const CommunityDetailCard = ({ id, name, role, postCount, members, walletAddress, onActionComplete }) => {
  const classes = useStyles();
  const priceDisplay = useCurrentSharePrice(walletAddress);

  const [openTransactionPopup, setOpenTransactionPopup] = useState(false);
  const [openJoinPopup, setOpenJoinPopup] = useState(false);
  const [action, setAction] = useState('');
  const [membershipCount, setMembershipCount] = useState(0)

  const userWalletAddress = useStoreState((state) => state.auth.walletAddress);
  const token = useStoreState((state) => state.auth.token);

  const isMATIC = name === COMMUNITY_NAME.CORNERMARKET || name === COMMUNITY_NAME.NIMBLE;
  const clearAuth = useStoreActions(
    (dispatch) => dispatch.auth.clearAuth
  );

  const handleOpenJoinPopup = () => {
     setOpenJoinPopup(true);
  };

  const handleCloseJoinPopup = () => {
    setOpenJoinPopup(false);
  };

  const handleQuit = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_HOST_ORIGIN}/v1/community/commonuser/${id}/delete`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + token,
          }
        }
      );
    
      if (!response.ok) {
        throw new Error('Request failed with status ' + response.status);
      }

      clearAuth();
      history.push('/login');
      console.log("Delete operation was successful");
    
    } catch (error) {
      console.error("An error occurred:", error.message);
    }
  }

  const handleOpenPopup = (button) => {
    setAction(button);
    setOpenTransactionPopup(true);
  };

  const handleClosePopup = () => {
    setOpenTransactionPopup(false);
  };

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  useEffect(() => {
    getContractViewer().keysBalance(walletAddress, userWalletAddress).then((balance) => {
        const balanceStr = balance.toString();
        const numericBalance = parseInt(balanceStr.match(/\d+/)[0], NUMBER_CONSTANTS.DECIMAL_BASE);
        setMembershipCount(numericBalance);
     });
}, [openTransactionPopup, openJoinPopup]);
  
  return (
    <div>
        <div className={classes.header}>
            <div 
                className={classes.communityName} 
                style={{ color: role === ROLE.MEMBER ? '#66FFFF' : 'white' }}
            >
                {name}
                <span style={{ color: role === ROLE.MEMBER ? '#66FFFF' : 'grey', fontWeight: 'normal' }}>
                    {role !== ROLE.NOBODY && ` - ${capitalizeFirstLetter(role)}${role === ROLE.MEMBER ? ` (${membershipCount})` : ''}`}
                </span>
            </div>
        </div>
        
        <div className={classes.communityCard}>
        
            <div className={classes.statsSection}>
                <div className={classes.statsBlock}>
                    <span className={classes.statsTitle}>Posts</span>
                    <span className={classes.statsValue}>{postCount}</span>
                </div>
                <div className={classes.statsBlock}>
                    <span className={classes.statsTitle}>Members</span>
                    <span className={classes.statsValue}>{members}</span>
                </div>
                <div className={classes.statsBlock}>
                    <span className={classes.statsTitle}>Membership Price</span>
                    <span className={classes.statsValue}>{priceDisplay.toFixed(5)} {isMATIC ? CURRENCY.MATIC : CURRENCY.ETH} </span>
                </div>
                <div className={classes.buttonsContainer}>

                  {role === ROLE.NOBODY ? (
                    <Button className={`${classes.button} join`} onClick={() => handleOpenJoinPopup()} >Join</Button>
                  ) : (
                    <>
                      <Button className={`${classes.button} buy`} onClick={() => handleOpenPopup(TRANSACTION.BUY)} >Buy</Button>
                      {role === ROLE.MEMBER && (
                        <Button className={`${classes.button} sell`} onClick={() => handleOpenPopup(TRANSACTION.SELL)} >Sell</Button>
                      )}
                      {role !== ROLE.MEMBER && (
                        <Button className={`${classes.button} quit`} onClick={() => handleQuit()} >Quit</Button>
                      )}
                    </>
                  )}
                </div>

                {openTransactionPopup && (<MembershipTransactionPopup action={action} onClose={handleClosePopup} onActionComplete={onActionComplete} communityName={name} communityWalletAddress={walletAddress} role={role} />)}
                {openJoinPopup && (<JoinPopup onClose={handleCloseJoinPopup} onActionComplete={onActionComplete} communityName={name} communityWalletAddress={walletAddress} id={id} />)}

            </div> 
        </div>
    </div>
  );
};

export default CommunityDetailCard;
