import React from 'react';
import { makeStyles } from "@material-ui/styles";
import { COMMUNITY_NAME, CURRENCY } from "../constants"; 

const useStyles = makeStyles({
    communityCard: {
      border: '0.5px solid #66FFFF',
      width: '100%',
      height: '68px', 
      boxSizing: 'border-box', 
      borderRadius: '10px',
      marginTop: '20px',
      color: 'white',
      display: 'flex', 
      flexDirection: 'column',
      justifyContent: 'center',
    },
    communityBody: {
      display: 'flex',
      justifyContent: 'space-between',
      textAlign: 'center',
      paddingLeft:'10px',
      paddingRight:'10px'
    },
    infoBlock: {
      padding: '5px',
      borderRadius: '10px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },
    infoTitle: {
      fontSize: "12px",
    },
    infoContent: {
      fontSize: "16px",
      display: 'block',
      marginTop: '5px',
      color: 'white',
    },
  });

const CommunitySummaryCard = ({ name, postNum, members, price, showName=false }) => {
    const classes = useStyles();
    const priceDisplay = typeof price === 'number' ? price.toFixed(5) : 0;
    const postNumDisplay = postNum || '-';
    const membersDisplay = members || 0;
    const isMATIC = name === COMMUNITY_NAME.CORNERMARKET || name === COMMUNITY_NAME.NIMBLE;

    return (
      <div className={classes.communityCard}>
        <div className={classes.communityBody}>
          {!showName && (
            <div className={classes.infoBlock}>
              <span className={classes.infoTitle}>Posts</span>
              <strong className={classes.infoContent}>{postNumDisplay}</strong>
            </div>
          )}
          {showName && (
            <div className={classes.infoBlock}>
              <span className={classes.infoTitle}>Name</span>
              <strong className={classes.infoContent}>{name}</strong>
            </div>
          )}
          <div className={classes.infoBlock}>
            <span className={classes.infoTitle}>Members</span>
            <strong className={classes.infoContent}>{membersDisplay}</strong>
          </div>
          <div className={classes.infoBlock}>
            <span className={classes.infoTitle}>Membership Price</span>
            <strong className={classes.infoContent}>{priceDisplay} {isMATIC ? CURRENCY.MATIC : CURRENCY.ETH} </strong>
          </div>
        </div>
      </div>
    );
  };
  
  export default CommunitySummaryCard;
