import AlertComponent from "../components/alert-component";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import React, { useState } from "react";
import { useAuth } from "../hooks/auth-hook";
import { useStoreActions, useStoreState } from "easy-peasy";
import { useHistory, useLocation } from 'react-router-dom';

const useStyles = makeStyles({
  connectWalletButton: {
    " &.MuiButtonBase-root": {
      fontSize: "14px",
      backgroundColor: "rgba(255, 255, 255, 0.1)",
      marginLeft: "auto",
      height: "29px",
      borderRadius: "1000px",
      border: "1.5px solid #66FFFF",
      color: "#66FFFF",
      "&:hover": {
        backgroundColor: "rgba(255, 255, 255, 0.2)",
      },
      textTransform: "none",
    },

    "&.connectWalletLogin": {
      backgroundColor: "#66FFFF",
      width: "100%",
      color: 'black',
      height: '50px',
      fontWeight: 'bold',
      marginTop: '50px'
    }
  },
  walletIcon: {
    marginRight: "7px",
    width: "14px",
    height: "14px",
  },
  walletInfo: {
    fontSize: "14px",
    color: "#66FFFF",
    backgroundColor: "rgba(255, 255, 255, 0.1)",
    borderRadius: "1000px",
    height: "29px",
    display: "flex",
    alignItems: "center",
    paddingLeft: "10px",
  },
  dropdownStyles: {
    fontSize: "14px",
    height: "29px",
    position: "absolute",
    top: "100%",
    backgroundColor: "rgba(255, 255, 255, 0.1)",
    color: "#66FFFF",
    border: "1px solid #66FFFF",
    borderRadius: "1000px",
    padding: "10px",
    zIndex: 1000,

    textTransform: "none",
  },
});

const ConnectWalletButton = ({ className }) => {
  const history = useHistory();
  const location = useLocation();
  const classes = useStyles();
  const { connectWallet } = useAuth();
  const [showAlert, setShowAlert] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const updateWalletAddress = useStoreActions(
    (actions) => actions.auth.updateWalletAddress
  );
  const walletAddress = useStoreState((state) => state.auth.walletAddress);
  const token = useStoreState((state) => state.auth.token);
  const clearAuth = useStoreActions(
      (dispatch) => dispatch.auth.clearAuth
  );

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleDisconnect = () => {
    setIsDropdownOpen(false);
    clearAuth();
    updateWalletAddress({ walletAddress: null, accessToken: null });
    history.push('/login');
  };

  const clickWalletButton = async () => {
    try {
      const result = await connectWallet();
      if (result.error) {
        handleError(result.error);
        return;
      }
      const accessToken = result.accessToken;
      const walletAddress = result.walletAddress;

      if (accessToken && result.userId) {
        updateWalletAddress({
          walletAddress: walletAddress,
          accessToken: accessToken,
        });
      } else if (accessToken) {
        updateWalletAddress({
          walletAddress: walletAddress,
          accessToken: accessToken,
          isAuthenticated: false,
        });
        if (location.pathname !== '/waitlist') {
            history.push('/join');
        }
      }
    } catch (error) {
      let message = error;
      try {
        const parsed = error.toString();
        message = parsed.split('(')[0];
      } catch (e) {
        message = error.toString();
      }
      handleError(message);
    }
  };

  const handleError = (error) => {
    setErrorMessage(error);
    setShowAlert(true);
  }

  const displayedAddress = walletAddress
    ? `${walletAddress.substring(0, 5)}...${walletAddress.substring(
        walletAddress.length - 3
      )}`
    : "";

  return (
    <div>
      {showAlert && (
        <AlertComponent
          isOpen={showAlert}
          message={errorMessage}
          onClose={() => setShowAlert(false)}
        />
      )}
      <div>
        {walletAddress && token ? (
          <div>
            <Button onClick={toggleDropdown} className={classes.walletInfo}>
              <img
                src={"/assets/wallet.png"}
                className={classes.walletIcon}
                alt="Wallet Icon"
              />
              {displayedAddress}
            </Button>
            {isDropdownOpen && (
              <Button
                onClick={handleDisconnect}
                className={classes.dropdownStyles}
              >
                Disconnect
              </Button>
            )}
          </div>
        ) : (
          <Button
            className={classes.connectWalletButton + ' ' + className}
            onClick={() => clickWalletButton()}
          >
            Connect Wallet
          </Button>
        )}
      </div>
    </div>
  );
};

export default ConnectWalletButton;
