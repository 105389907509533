import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { useAuth } from "../hooks/auth-hook";
import {Button, Paper} from "@mui/material";
import {useStoreActions, useStoreState} from "easy-peasy";
import { makeStyles } from "@material-ui/styles";
import UserSummaryCard from "../components/user-summary-card-component.js";
import {handleAuthError} from "../services/auth-service";
import InfiniteScroll from "react-infinite-scroll-component";
import history from "../util/history-util.js";
import { useCurrentSharePrice } from "../hooks/useCurrentSharePrice";
import CommunityWithPoints from "../components/community-points-component.js";
import axios from "axios";
import {
  Avatar,
  CardHeader,
  Typography,
} from "@mui/material";

/**
 * The community main page, which displays a list of Posts
 * @returns {JSX.Element}
 * @constructor
 */

const useProfileStyles = makeStyles({
  postList: {

    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  postListWrap: {
    maxWidth: "345px",
    width: "100%",
    paddingTop: '50px',
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "flex-start",
    "&.MuiPaper-root": {
      backgroundColor: "transparent",
    },
  },
  logoutButton: {
    " &.MuiButtonBase-root": {
      marginTop: "12px",
      width: "100%",
      height: "48px",
      backgroundColor: "#66FFFF",
      color: "#161818",
      borderRadius: "1000px",
      padding: "10px 60px",
      fontSize: "18px !important",
      textTransform: "none",
      fontWeight: "bold",
      boxShadow: "none",
      "&:hover": {
        backgroundColor: "#00D4D4",
        boxShadow: "none",
      },
    }
  },
});

const PostList = (props) => {
  const { getUserSummary } = useAuth();
  const [points, setPoints] = useState(0);
  const [communityCount, setCommunityCount] = useState(0);
  const [username, setUsername] = useState("");
  const [postCount, setPostCount] = useState(0);
  const [avatar, setAvatar] = useState("");
  const [communities, setCommunities] = useState([]);
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(10);
  const [hasMore, setHasMore] = useState(true);

  const classes = useProfileStyles();

  const token = useStoreState((state) => state.auth.token);
  const walletAddress = useStoreState((state) => state.auth.walletAddress);
  const selectedCommunity = useStoreState((state) => state.settings.currentSelectedCommunity);
  const sharePrice = useCurrentSharePrice(selectedCommunity?.walletAddress);


  const clearAuth = useStoreActions(
      (dispatch) => dispatch.auth.clearAuth
  );

  const handleAvatarClick = () => {
    history.push('/avatar/edit');
  };


  const loadMore = async (page) => {


    try {
      const { data } = await axios.request({
        method: 'GET',
        url: `${process.env.REACT_APP_HOST_ORIGIN}/v1/community/user/community/profile?offset=${offset}`,
        headers: {
          'Authorization': `Bearer ${token}`
        },
      });

      if (!data || data.length === 0) {
        setHasMore(false);
      }
      setOffset(data.length + offset);
      setCommunities([...communities, ...data]);
    } catch (error) {
      console.error('Error fetching user info:', error);
      handleAuthError(error, clearAuth);
    }
  };

  useEffect(() => {
    const fetchUserInfo = async () => {
      try {
        const { data } = await axios.request({
          method: 'GET',
          url: `${process.env.REACT_APP_HOST_ORIGIN}/v1/community/user/info`,
          headers: {
            'Authorization': `Bearer ${token}`
          },
        });
        if (data) {
          setUsername(data.username);
          setAvatar(data.avatarUrl);
        }
      } catch (error) {
        console.error('Error fetching user info:', error);
        handleAuthError(error, clearAuth);
      }
    };
    if (token) {
      fetchUserInfo();
      window.scrollTo(0, 0);
      loadMore(0);
    }
  }, [token, clearAuth]);


  useEffect(() => {
    const fetchUserSummary = async () => {
      const data = await getUserSummary(token);
      setCommunityCount(data.communityCount);
      setPoints(data.xp);
      setPostCount(data.postCount);
      if (data.message) {
        handleAuthError(data, clearAuth);
      }
    }
    fetchUserSummary();
  }, [clearAuth, token]);


  return (
      <Paper elevation={0} className={classes.postListWrap}>
        <CardHeader
          avatar={
             <Button onClick={handleAvatarClick} sx={{ padding: 0, width: '32px', minWidth: '32px' }} > 
              <Avatar sx={{ width: '32px', height: '32px', bgcolor: "#66FFFF" }} aria-label="user" src={avatar || undefined}>
                { username.charAt(0).toUpperCase()}
              </Avatar>
             </Button>
          }
          sx={{ padding: "12px 0 0 0" }}
          title={<Typography sx={{ fontWeight: 600 }}>{username}</Typography>}
        />
        <UserSummaryCard communityCount = {communityCount} postCount = {postCount} points = {points} />
        <Button className={classes.logoutButton} onClick={() => clearAuth()}>Log out</Button>
        <InfiniteScroll
            pageStart={0}
            dataLength={communities.length}
            hasMore={hasMore}
            next={loadMore}
            loader={<div></div>}
        >
          <div className={classes.eventList}>
            {communities.map((community, index) => (
                <CommunityWithPoints key={index} {...community} />
            ))}
          </div>
        </InfiniteScroll>
      </Paper>
  );
};

export default withRouter(PostList);
