import React, {useState} from "react";
import IconButton from "@material-ui/core/IconButton";
import ShareIcon from '@mui/icons-material/Share';
import { useHistory } from 'react-router-dom';
import { makeStyles } from "@material-ui/core/styles";
import CreateInviteComponent from "./create-invite-component";

const useStyles = makeStyles({
  icon: {
    width: '24px',
    height: '24px',
    color: '#88FFFF'
  }
});

const ActionHeaderButtons = () => {
    const classes = useStyles();
    const history = useHistory();
    const [showInvite, setShowInvite] = useState(false);

    const handleReminderClick = () => {
    };

    const handleInviteGenerationClick = () => {
        setShowInvite(true);
    };

    const handleNewPostClick = () => {
        history.push('/post/new');
    };

    return (
        <div>
            <IconButton className={classes.button} onClick={handleInviteGenerationClick}>
                <ShareIcon className={classes.icon} />
            </IconButton>
            <IconButton className={classes.button} onClick={handleNewPostClick}>
                <img src={"/assets/new-post.png"} className={classes.icon} alt="Icon Three" />
            </IconButton>
            {showInvite && (
            <CreateInviteComponent
                isOpen={showInvite}
                onClose={() => setShowInvite(false)}
            />
            )}
        </div>
    );
};

export default ActionHeaderButtons;
