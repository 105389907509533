import React, { useEffect } from "react";
import { useStoreState, useStoreActions } from "easy-peasy";
import { useLocation } from "react-router-dom";
import { useHistory } from 'react-router-dom';
import axios from "axios";

import {
  FormControl,
  MenuItem,
  Select,
} from "@mui/material";
import { makeStyles } from "@material-ui/styles";

const useSwitcherStyles = makeStyles({
  badgeWrap: {
    backgroundColor: "rgba(102, 255, 255, 1)",
    fontWeight: "700",
    borderRadius: "0 6px 6px 6px",
    margin: "0 !important",
  },
  communityBadge: {
    "& .MuiSelect-outlined": {
      padding: "0 12px",
      color: "#161818",
      fontSize: "12px",
      fontWeight: "600",
    },
    "& .MuiSvgIcon-root": {
      color: "#161818",
    },
  },
});

export const CommunitySwitcherComponent = () => {

  const classes = useSwitcherStyles();
  const location = useLocation();
  const history = useHistory();

  const isCommunityPage = /^\/c\/.+/.test(location.pathname);
  
  const { updateSelectedCommunity } = useStoreActions((dispatch) => ({
    updateSelectedCommunity: dispatch.settings.updateSelectedCommunity,
  }));
  const { isAuthenticated, token } = useStoreState((state) => state.auth);
  const selectedCommunity = useStoreState((state) => state.settings.currentSelectedCommunity);
  const [communities, setCommunities] = React.useState([]);

  const handleChange = (event) => {
    updateSelectedCommunity(event.target.value);
    if (isCommunityPage) {
      history.push(`/c/${event.target.value}`);
    } else {
      history.push(`/stats/${event.target.value}`);
    }
  };

  useEffect(() => {
    if (isAuthenticated) {
      axios.request({
        method: 'GET',
        url: `${process.env.REACT_APP_HOST_ORIGIN}/v1/community/user/community/partial`,
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
      .then(({ data }) => {
        setCommunities(data);

      });
    }

  }, [isAuthenticated, token]);

  return (
    <FormControl sx={{ m: 1, minWidth: 120 }} className={classes.badgeWrap}>
      <Select
        sx={{
          boxShadow: "none",
          ".MuiOutlinedInput-notchedOutline": { border: 0 },
        }}
        value={selectedCommunity?.id}
        className={ classes.communityBadge }
        displayEmpty={false}
        inputProps={{ "aria-label": "Select Community" }}
        onChange={(event) => handleChange(event)}
      >
        
        {communities.map((community, i) => (
          <MenuItem value={community?.id} key={i}>
            {community.communityName}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
