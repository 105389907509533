import {
  checkLocalStorage,
  setInLocalStorage,
} from "../util/localstorage-util";
import { action, thunk } from "easy-peasy";

const CURRENT_COMMUNITY = "nimble-community-current-community";

// reyhdrate state from localstorage if it exist
const initialState = {
  currentThemeSelection: "dark",
  currentSelectedCommunity: checkLocalStorage(
    CURRENT_COMMUNITY,
    undefined
  ),
  userAllowedCommunities: "",
  postCreateMode: false,
};

export const settingsModel = {
  settings: {
    ...initialState,
    updateSelectedTheme: (state, payload) => {
      // update the state
      state.currentThemeSelection = payload;

      // store the current theme selection in localstorage
      setInLocalStorage("currentThemeSelection", payload);
    },
    updateSelectedCommunity: action((state, payload) => {
      state.currentSelectedCommunity = payload;
      setInLocalStorage(CURRENT_COMMUNITY, payload);
    }),
    // available community get/set
    getAvailableCommunities: thunk(async (dispatch, payload, getState) => {
      const { userAllowedCommunities } = payload;
      dispatch.user.updateAvailableCommunities(userAllowedCommunities);
    }),
    updateAvailableCommunities: (state, payload) => {
      state.userAllowedCommunities = payload;
    },
    // post creation mode get/set
    getPostCreateMode: thunk(async (dispatch, payload, getState) => {
      const { postCreateMode } = payload;
      dispatch.user.updateSelectedCommunity(postCreateMode);
    }),
    updatePostCreateMode: (state, payload) => {
      state.postCreateMode = payload;
    },
  },
};
