import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import { useCurrentSharePrice } from "../hooks/useCurrentSharePrice";
import { COMMUNITY_NAME, CURRENCY } from "../constants"; 

const useStyles = makeStyles({
  communityBox: {
    borderRadius: '8px',
    padding: '16px',
    backgroundColor: 'rgba(255, 255, 255, 0.05)',
    fontFamily: 'Arial, sans-serif',
    width: '345px',
    marginTop: '20px'
  },
  communityHeader: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '16px',
  },
  communityName: {
    fontSize: '16px',
    color: '#66FFFF',
    fontWeight: '700',
    marginRight: '7px',
  },
  editIcon: {
    cursor: 'pointer',
  },
  communityInfo: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  label: {
    display: 'block',
    fontSize: '12px',
    color: '#FFFFFF',
    marginRight: '12px',
    fontWeight: '300',
  },
  value: {
    display: 'block',
    fontSize: '12px',
    fontWeight: '700',
  },
  communityProgress: {
    marginTop: '17px',
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
  },
  progressBar: {
    width: '100%',
    borderRadius: '8px',
    height: '10px',
    backgroundColor: 'rgba(208, 208, 208, 0.2)',
    '& .MuiLinearProgress-bar': {
        backgroundColor: '#1CF98F'
    }
  },
  infoSection: {
    display: 'flex',
    alignItems: 'center',
  },
  nextRank: {
    width: '65px',
    fontSize: '12px',
    fontWeight: '300',
    color: '#FFFFFF',
  },
  rank: {
    fontSize: '16px',
    fontWeight: 400,
    color: '#85A7A7',
  },
});

const CommunityWithPoints = ({ communityName, maxPoints, minPoints, rank, points, role, walletAddress }) => {
  const classes = useStyles();
  const pointNextRank = (points - minPoints) / (maxPoints - minPoints) * 100
  const membershipPrice = useCurrentSharePrice(walletAddress).toFixed(5);
  const isMATIC = communityName === COMMUNITY_NAME.CORNERMARKET || communityName === COMMUNITY_NAME.NIMBLE;

  return (
    <div className={classes.communityBox}>
      <div className={classes.communityHeader}>
        <span className={classes.communityName}>{communityName}</span>
        <span className={classes.rank}> (Rank {rank})</span>
      </div>
      <div className={classes.communityInfo}>
        <div className={classes.infoSection}>
            <span className={classes.label}>Points</span>
            <span className={classes.value}>{points}</span>
        </div>
        <div className={classes.infoSection}>
            <span className={classes.label}>Membership Price</span>
            <span className={classes.value}>{membershipPrice} {isMATIC ? CURRENCY.MATIC : CURRENCY.ETH} </span>
        </div>
      </div>
      <div className={classes.communityProgress}>
        <span className={classes.nextRank}>Next Rank</span>
        <LinearProgress variant="determinate" value={pointNextRank} className={classes.progressBar} />
      </div>
    </div>
  );
};

export default CommunityWithPoints;
