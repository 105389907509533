import React, { useState, useEffect, useCallback } from "react";
import {useLocation, withRouter} from "react-router-dom";
import { useAuth } from "../hooks/auth-hook";
import { makeStyles } from "@material-ui/styles";
import AlertComponent from "../components/alert-component";
import {Button, CircularProgress, TextField, Select, MenuItem} from "@mui/material";
import { useStoreActions, useStoreState } from "easy-peasy";
import CommunitySummaryCard from "../components/community-summary-card-component";
import { ethers } from "ethers";
import { CommunityKeysV1__factory } from "nimble-community-contracts-sdk";
import { useCurrentSharePrice } from "../hooks/useCurrentSharePrice";
import {checkUserAvailability} from "../services/auth-service";
import { isValidDiscordUsername } from "../services/auth-service";
import history from "../util/history-util";
import useKeysBought from "../hooks/useKeysBought";
import clsx from "clsx";
import axios from "axios";
import { TRANSACTION, ERROR_CODE, JOIN_STEP } from "../constants";

/**
 * The Join page, which allows users to sign up using an invite code
 * Inputs
 * - Invite code
 * - Connected Wallet
 * - Discord Handle
 * - Username
 * @returns {JSX.Element}
 * @constructor
 */

const joinPageStyles = makeStyles({
  inputWrapper: {
    marginBottom: "20px",
  },
  label: {
    color: "white",
    margin: "8px 0 16px",
    fontWeight: "bold",
  },

  submit: (props) => ({
    paddingTop: props.isEnterDetailStep ? "135px" : "200px",
  }),

  backgroundBlur: {
    background: "radial-gradient(circle, rgba(102,255,255,0.2) 0%, rgba(22,24,24,1) 40%)",
    backgroundSize: 'cover',
    width: '100%',
    height: '100vh',
    backgroundPositionY: '-20vh',
    backgroundPositionX: 'center',
    backgroundRepeat: 'no-repeat',
    position: 'absolute',
    zIndex: '0',
  },
  selectContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  selectStyle: {
    width: '100%',
    fontSize: '18px !important',
    textAlign: 'center',
    '& .MuiSelect-select': {
      textAlign: 'center !important',
      textAlignLast: 'center',
    },
  },
  menuItem: {
    justifyContent: 'center !important',
    fontSize: '16px !important',
  },

  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  formWrap: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center'
  },
  form: {
    width: '100%',
    maxWidth: '320px',
  },
  waitlistForm: {
    width: '100%',
    paddingBottom: '48px',
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '500px',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: '1',
  },
  graphic: {
    backgroundImage: "url('/assets/nimble-graphic.png')",
    background: "no-repeat center center",
    backgroundSize: "contain",
    width: "100%",
    height: '200px',
    marginTop: '120px'
  },
  copyText: {
    fontWeight: '400',
    fontSize: '1em',
    textAlign: 'center',
    color: '#66FFFF',
    marginTop: '0'
  },
  inputField: {
    width: "100%",
    height: "48px",
    "& .MuiInputBase-input": {
      borderRadius: "12px",
      backgroundColor: "rgba(0, 0, 0, 0.7)",
      color: "#bbbbbb",
    },
    "& .MuiInputLabel-root": {
      color: "#bbbbbb",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        border: "0px",
      },
      "&:hover fieldset": {
        border: "1px solid #66FFFF",
      },
      "&.Mui-focused fieldset": {
        border: "1px solid #66FFFF",
      },
    },
  },

  errorField: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        border: "1px solid red",
      },
      "&:hover fieldset": {
        border: "1px solid red",
      },
      "&.Mui-focused fieldset": {
        border: "1px solid red",
      },
    }
  },

  errorText: {
    paddingTop: '15px',
    color: 'red',
    fontSize: '10px',
  },

  joinButton: {
    "&.MuiButtonBase-root": {
      width: "100%",
      height: "48px",
      backgroundColor: "#66FFFF",
      color: "#161818",
      borderRadius: "1000px",
      padding: "10px 60px",
      fontSize: "18px",
      textTransform: "none",
      fontWeight: "bold",
      boxShadow: "none",
      "&:hover": {
        backgroundColor: "#00D4D4",
        boxShadow: "none",
      },
      "&:disabled": {
        cursor: "not-allowed",
        backgroundColor: "#A2A2A2",
        color: "#404040"
      }
    }
  },
  joinGusetButton: {
    marginTop: '16px !important',
    "&.MuiButtonBase-root": {
      backgroundColor: "white",
      color: "#161818",
      "&:hover": {
        backgroundColor: "#ddd",
        boxShadow: "none",
      },
      "&:disabled": {
        cursor: "not-allowed",
        backgroundColor: "#A2A2A2",
        color: "#404040"
      }
    }
  },
});

const Join = () => {

  const { signupWithWallet, validateInviteCode, getCommunityData } = useAuth();

  const [inviteCode, setInviteCode] = useState("");
  const [inviteCodeIsValid, setInviteCodeIsValid] = useState(false);

  const [showLoader, setShowLoader] = useState(false);

  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const urlCode = searchParams.get("code");

  const [isUserSignedUp, setIsUserSignedUp] = useState(false); 

  const [communityId, setCommunityId] = useState();
  const [currentStep, setCurrentStep] = useState(JOIN_STEP.VALIDATE_CODE);
  const [communityName, setCommunityName] = useState("");
  const [communityMember, setCommunityMember] = useState(0);
  const [communityKeysSubject, setCommunityKeysSubject] = useState();
  const sharePrice = useCurrentSharePrice(communityKeysSubject);
  const [communities, setCommunities] = useState([]);


  const [username, setUsername] = useState("");
  const [usernameAlertMessage, setUsernameAlertMessage] = useState("");
  const [usernameAlert, setUsernameAlert] = useState(false);

  const [discordHandle, setDiscordHandle] = useState("");
  const [discordHandleAlertMessage, setDiscordHandleAlertMessage] = useState("");
  const [discordHandleAlert, setDiscordHandleAlert] = useState(false);

  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");

  const walletAddress = useStoreState((state) => state.auth.walletAddress);
  const token = useStoreState((state) => state.auth.token);

  const isEnterDetailStep = currentStep === JOIN_STEP.ENTER_DETAIL;
  const classes = joinPageStyles({ isEnterDetailStep });

  const handleCommunitySelect = (event) => {
    const selectedCommunityId = event.target.value;
    const community = communities.find(c => c.id === selectedCommunityId);

    setCommunityId(community.id);
    setCommunityMember(community.memberCount);
    setCommunityName(community.communityName);
    setCommunityKeysSubject(community.walletAddress);
  };

  useEffect(() => {
    axios.request({
      method: 'GET',
      url: `${process.env.REACT_APP_HOST_ORIGIN}/v1/community/user/community/selectable`,
      headers: {
        'Authorization': `Bearer ${token}`
      },
    })
    .then(({ data }) => {
      const selectableWithFlag = data.selectable 
        ? data.selectable.map(item => ({ ...item, isEnabled: true })) 
        : [];
        
      const unselectableWithFlag = data.unselectable 
        ? data.unselectable.map(item => ({ ...item, isEnabled: false })) 
        : [];
      setCommunities([...selectableWithFlag, ...unselectableWithFlag]);
    }).catch((error) => {
      console.error('error', error);
    });
  }, [token]);


  useEffect(() => {
    axios.request({
      method: 'GET',
      url: `${process.env.REACT_APP_HOST_ORIGIN}/v1/community/user/info`,
      headers: {
        'Authorization': `Bearer ${token}`
      },
    })
    .then(({ data }) => {
      setIsUserSignedUp(true);
      setUsername(data.username);
      setDiscordHandle(data.discordHandle);
    }).catch((error) => {
      console.error('error', error);
    });
  }, [token]);

  let keysBought = useKeysBought(walletAddress, communityKeysSubject);

  const updateWalletAddress = useStoreActions(
      (actions) => actions.auth.updateWalletAddress
  );

  const clearAuth = useStoreActions(
      (dispatch) => dispatch.auth.clearAuth
  );

  const handleInviteCodeValidation = async () => {
    if (!walletAddress || !token) {
      setAlertMessage(
          "You must connect your wallet before joining into our community."
      );
      setShowAlert(true);
      return;
    }
    try {
      const { accessToken, invite } = await validateInviteCode(inviteCode, token);
      if (accessToken) {
        setInviteCodeIsValid(true);
        updateWalletAddress({ walletAddress: walletAddress, accessToken: accessToken, isAuthenticated: false });
        const data = await getCommunityData(invite.community);
        setCommunityId(data.id);
        setCommunityMember(data.memberCount);
        setCommunityName(data.communityName);
        setCommunityKeysSubject(data.walletAddress);
        setCurrentStep(JOIN_STEP.SELECT_COMMUNITY);
      }
    } catch(error) {
      if (error.response?.status === 401) {
        clearAuth();
        history.push('/login');
        return;
      }
      if (error.response?.data?.message) {
        setAlertMessage(error.response.data.message);
        setShowAlert(true);
        return;
      }
      setAlertMessage(error.message);
      setShowAlert(true);
      return;
    }
  };

  const handleCommunitySelection = () => {
    const community = communities.find(community => community.id === communityId);
    if (!community.isEnabled) {
      setAlertMessage(
        "Please select a community which you have not joined in yet."
      );
      setShowAlert(true);
    } else {
      setCurrentStep(JOIN_STEP.ENTER_DETAIL);
    }
  };

  const handleJoin = useCallback(async (isJoinAsGuest = false) => {
    const minLength = 2;
    const maxLength = 32;

    if (!communityId) {
      setAlertMessage("Error fetching community data");
      setShowAlert(true);
      return;
    }

    if (!walletAddress || !token) {
      setAlertMessage(
          "You must connect your wallet before joining into our community."
      );
      setShowAlert(true);
      return;
    }

    if (!isUserSignedUp) {
      const {usernameAvailable, discordHandleAvailable, walletIdAvailable} = await checkUserAvailability(discordHandle, username, walletAddress);
      if (!usernameAvailable) {
        setUsernameAlertMessage(`That username is already in use`);
        setUsernameAlert(true);
        return;
      }

      if (!discordHandleAvailable) {
        setDiscordHandleAlertMessage(`That discord handle is already in use`);
        setDiscordHandleAlert(true);
        return;
      }

      if (!walletIdAvailable) {
        setAlertMessage(`There is already an account for that wallet address`);
        setShowAlert(true);
        return;
      }

      if (username.length < minLength || username.length > maxLength) {
        setUsernameAlertMessage(`Username must be between ${minLength} and ${maxLength} characters long.`);
        setUsernameAlert(true);
        return;
      }

      try {
        isValidDiscordUsername(discordHandle);
      } catch (error) {
        setDiscordHandleAlertMessage(error.message);
        setDiscordHandleAlert(true);
        return;
      }
    }

    try {
      if (sharePrice === null || sharePrice === undefined) throw new Error("Error fetching share price");
      if (keysBought === 0 && !isJoinAsGuest) {
        const provider = new ethers.BrowserProvider(window.ethereum);
        const signer = await provider.getSigner();
        const contractAddress = process.env.REACT_APP_COMMUNITY_CONTRACT_ADDRESS;
        const communityContract = CommunityKeysV1__factory.connect(contractAddress, signer);

        let receipt;
        let tx;

        try {
          //const feeData = await provider.getFeeData();
          const options = {
            gasLimit: TRANSACTION.GAS_LIMIT,
            //gasPrice: feeData.gasPrice || ethers.parseUnits("1.6", "gwei"),
            // maxPriorityFeePerGas: feeData.maxPriorityFeePerGas,
            // maxFeePerGas: feeData.maxFeePerGas,
            value: ethers.parseEther(`${sharePrice}`, "ether"),
          };
          tx = await communityContract.buyKeys(communityKeysSubject, 1, options);

          setShowLoader(true);
          receipt = await tx.wait().then((receivedReceipt) => {
            setShowLoader(false);
            return receivedReceipt
          });
        } catch (error) {
          console.error("Error buying membership:", error);
          throw new Error("Error buying membership");
        }
        if (receipt.status !== ERROR_CODE.NO_ERROR) {
          throw new Error('Transaction of buying membership failed');
        }
        console.log("Transaction hash:", tx.hash);
        console.log("Transaction status:", receipt?.status);
      }
      
      let accessTokenReturned;
      setShowLoader(true);
      if (!isUserSignedUp) {
        accessTokenReturned = await signupWithWallet(inviteCode, communityId, username, discordHandle, token);
      } else {
        const { data } = await axios.request({
          method: 'POST',
          url: `${process.env.REACT_APP_HOST_ORIGIN}/v1/community/commonuser/community/invite`,
          headers: {
            'Authorization': `Bearer ${token}`
          },
          data: {
            communityId,
            inviteCode,
          }
        });
        accessTokenReturned = data.accessToken;
      }
      setShowLoader(false);
      if (accessTokenReturned) {
        updateWalletAddress({ walletAddress: walletAddress, accessToken: accessTokenReturned, isAuthenticated: true });
        setShowAlert(true);
        setAlertMessage(`Congratulations! You have joined the ${communityName} community`);
      } else {
        throw new Error(`Error signing up and joining in community ${communityName}`);
      }
      // TODO: push to the coresponding community page
      history.push(`/c/${communityId}`);
    } catch(error) {
      // Todo: use error code instead of error message to justify error 
      if (error.message === "The username is already in use. Please choose another name.") {
        setUsernameAlert(true);
        setUsernameAlertMessage(error.message);
        return;
      }

      if (error.message === "The discord handle is already in use. Please choose another one.") {
        setDiscordHandleAlert(true);
        setDiscordHandleAlertMessage(error.message);
        return;
      }

      setAlertMessage(error.message);
      setShowAlert(true);
      return;
    }
  }, [communityId, communityKeysSubject, communityName, discordHandle, inviteCode, isUserSignedUp, keysBought, sharePrice, signupWithWallet, token, updateWalletAddress, username, walletAddress]);

  useEffect(() => {
    setInviteCode(urlCode || '');
  }, []);

  return (
      <div className={classes.formWrap}>
        {showAlert && (
            <AlertComponent
                isOpen={showAlert}
                message={alertMessage}
                onClose={() => setShowAlert(false)}
            />
        )}
        <div className={classes.backgroundBlur}></div>
        <div className={classes.waitlistForm}>
          <div className={classes.graphic}></div>
          <h4 className={classes.copyText}>Enter your invite code to join Nimbus open beta<br/>Your community awaits</h4>
          <form noValidate className={classes.form}>
            <div className={classes.inputWrapper}>
              {currentStep === JOIN_STEP.VALIDATE_CODE && (
                  <div>
                    <div className={classes.label}>Invite Code</div>
                    <TextField
                        className={classes.inputField}
                        label="Enter Invite Code"
                        variant="outlined"
                        value={inviteCode}
                        onChange={(e) => setInviteCode(e.target.value)}/>
                  </div>
              )}
              
              {currentStep === JOIN_STEP.SELECT_COMMUNITY && (
                <div className={classes.selectContainer}>
                  <Select
                    className={classes.selectStyle}
                    value={communityId}
                    onChange={handleCommunitySelect}
                    MenuProps={{ 
                      PaperProps: { 
                        sx: { 
                          maxHeight: 200,
                          overflowY: 'auto',
                          '&::-webkit-scrollbar': {
                            display: 'none',
                          },
                          '-ms-overflow-style': 'none',
                          'scrollbar-width': 'none',
                        } 
                      },
                    }}
                  >
                    {communities.map((community) => (
                      <MenuItem 
                        key={community.id} 
                        value={community.id}
                        disabled={!community.isEnabled} 
                        className={classes.menuItem} 
                      >
                        {community.communityName}
                      </MenuItem>
                    ))}
                  </Select>
                </div>
              )}

              {currentStep === JOIN_STEP.ENTER_DETAIL && (
                  <div>
                    <div className={classes.label}>Username</div>
                    <TextField
                        className={`${classes.inputField} ${usernameAlert ? classes.errorField : ''}`}
                        label="Enter Username"
                        variant="outlined"
                        value={username}
                        disabled={isUserSignedUp}
                        style={ isUserSignedUp ? {pointerEvents: 'none'} : {} }
                        onChange={(e) => {
                          setUsername(e.target.value);
                          if (usernameAlert) {
                            setUsernameAlert(false);
                          }
                        }}
                    />
                    {usernameAlert && <div className={classes.errorText}>{usernameAlertMessage}</div>}
                    <div className={classes.label} style={{marginTop: '20px'}}>Discord Handle</div>
                    <TextField
                        className={`${classes.inputField} ${discordHandleAlert ? classes.errorField : ''}`}
                        label="Enter Discord handle"
                        variant="outlined"
                        value={discordHandle}
                        disabled={isUserSignedUp}
                        style={ isUserSignedUp ? {pointerEvents: 'none'} : {} }
                        onChange={(e) => {
                          setDiscordHandle(e.target.value);
                          if (discordHandleAlert) {
                            setDiscordHandleAlert(false);
                          }
                        }}
                    />
                    {discordHandleAlert && <div className={classes.errorText}>{discordHandleAlertMessage}</div>}
                    <div className={classes.container}>
                      <CommunitySummaryCard name={communityName} members={communityMember} price={sharePrice} showName={true} />
                    </div>
                  </div>
              )}
            </div>
            <div className={classes.submit}>
              {currentStep === JOIN_STEP.VALIDATE_CODE && (
                  <Button type="button" className={classes.joinButton} onClick={handleInviteCodeValidation}> Next </Button>
              )}

              {currentStep === JOIN_STEP.SELECT_COMMUNITY && (
                  <Button type="button" className={classes.joinButton} onClick={handleCommunitySelection}> Next </Button>
              )}

              {currentStep === JOIN_STEP.ENTER_DETAIL && !showLoader && (
                <>
                  <Button type="button" className={classes.joinButton} onClick={() => handleJoin(false)}>{ keysBought > 0 ? 'Join as a Member' : 'Buy Membership' }</Button>
                  <Button type="button" className={clsx(classes.joinButton, classes.joinGusetButton)} onClick={() => handleJoin(true)}>Join as a Guest</Button>
                </>
              )}
              {currentStep === JOIN_STEP.ENTER_DETAIL && showLoader && (
                  <Button type="button" className={classes.joinButton} disabled>
                    Processing...
                    <CircularProgress />
                  </Button>
              )}
            </div>
          </form>
        </div>
      </div>
  );
};

export default withRouter(Join);
