import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { useAuth } from "../hooks/auth-hook";
import { makeStyles } from "@material-ui/styles";
import { Button, TextField } from "@mui/material";
import { useStoreState } from "easy-peasy";
import {
  isValidEmailAddress,
  isValidDiscordUsername,
} from "../services/auth-service";
import AlertComponent from "../components/alert-component";

/**
 * The Waitlist page, which allows users to register for the official waitlist
 * Inputs
 * - Connected Wallet
 * - Discord Handle
 * @returns {JSX.Element}
 * @constructor
 */

const useNavStyles = makeStyles({
  inputWrapper: {
    marginBottom: "20px",
  },
  label: {
    color: "white",
    margin: "8px 0 16px",
    fontWeight: "bold",
  },

  submit: {
    paddingTop: "135px",
  },
  backgroundBlur: {
    background: "radial-gradient(circle, rgba(102,255,255,0.2) 0%, rgba(22,24,24,1) 40%)",
    backgroundSize: 'cover',
    width: '100%',
    height: '100vh',
    backgroundPositionY: '-20vh',
    backgroundPositionX: 'center',
    backgroundRepeat: 'no-repeat',
    position: 'absolute',
    zIndex: '0',
  },
  formWrap: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center'
  },
  form: {
    width: '100%',
    maxWidth: '320px',
  },
  waitlistForm: {
    width: '100%',
    paddingBottom: '48px',
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '500px',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: '1',
},
  graphic: {
    backgroundImage: "url('/assets/nimble-graphic.png')",
    background: "no-repeat center center",
    backgroundSize: "contain",
    width: "100%",
    height: '200px',
    marginTop: '120px'
  },
  copyText: {
    fontWeight: '400',
    fontSize: '1em',
    textAlign: 'center',
    color: '#66FFFF',
    marginTop: '0'
  },
  inputField: {
    width: "100%",
    height: "48px",
    "& .MuiInputBase-input": {
      borderRadius: "12px",
      backgroundColor: "rgba(0, 0, 0, 0.7)",
      color: "#bbbbbb",
    },
    "& .MuiInputLabel-root": {
      color: "#bbbbbb",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "transparent",
      },
      "&:hover fieldset": {
        borderColor: "#bbbbbb",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#bbbbbb",
      },
    },
  },

  joinButton: {
    width: "100%",
    height: "48px",
    backgroundColor: "#66FFFF !important",
    color: "#161818 !important",
    borderRadius: "1000px !important",
    padding: "10px 60px",
    fontSize: "18px !important",
    textTransform: "none",
    fontWeight: "bold !important",
    boxShadow: "none",
    "&:hover": {
      backgroundColor: "#00D4D4",
      boxShadow: "none",
    },
  },
});

const Waitlist = () => {
  const [discordHandle, setDiscordHandle] = useState("");
  const [email, setEmail] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const { addIntoWaitlist } = useAuth();
  const classes = useNavStyles();

  const walletAddress = useStoreState((state) => state.auth.walletAddress);
  const token = useStoreState((state) => state.auth.token);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!walletAddress || !token) {
      setAlertMessage(
        "You must connect your wallet before joining to the waitlist."
      );
      setShowAlert(true);
      return;
    }

    if (!isValidEmailAddress(email)) {
      setAlertMessage("Please type in email with correct format.");
      setShowAlert(true);
      return;
    }

    try {
      isValidDiscordUsername(discordHandle);
    } catch (error) {
      setAlertMessage(error.message);
      setShowAlert(true);
      return;
    }

    try {
      await addIntoWaitlist(discordHandle, email, token);
      setEmail("");
      setDiscordHandle("");
      setAlertMessage(
        "Congratulations! You have been added to our waitlist. Please await further contact for the next steps."
      );
      setShowAlert(true);
      return;
    } catch (error) {
      setAlertMessage(error.message);
      setShowAlert(true);
      return;
    }
  };

  return (
    <div className={classes.formWrap}>
      {showAlert && (
        <AlertComponent
          isOpen={showAlert}
          message={alertMessage}
          onClose={() => setShowAlert(false)}
        />
      )}
      <div className={classes.backgroundBlur}></div>
      <div className={classes.waitlistForm}>
        <div className={classes.graphic}></div>
        <h4 className={classes.copyText}>Join the waitlist for Nimbus open beta<br/>Your community awaits</h4>
        <form noValidate className={classes.form}>
          <div className={classes.inputWrapper}>
            <div className={classes.label}>Email</div>
            <TextField
              className={classes.inputField}
              label="Enter Email"
              variant="outlined"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className={classes.inputWrapper}>
            <div className={classes.label}>Discord Handle</div>
            <TextField
              className={classes.inputField}
              label="Enter Discord Handle"
              variant="outlined"
              value={discordHandle}
              onChange={(e) => setDiscordHandle(e.target.value)}
            />
          </div>
          <div className={classes.submit}>
            <Button
              type="button"
              className={classes.joinButton}
              onClick={handleSubmit}
            >
              Join Waitlist
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default withRouter(Waitlist);
