import { postCommentLikeUnlike } from "../services/community-service"; 

export const useComment = () => {
    const commentLikeUnlike = async (token, like, commentId) => {
        try {
            await postCommentLikeUnlike(token, like, commentId);
        } catch (error) {
          throw error;
        }
      }

  return { commentLikeUnlike };
};
